import { all, put, takeEvery } from "@redux-saga/core/effects";
import store from "../store/store";
import { getCurrentUserClaims } from "../../services/authentication";
import {
  removeClinicRequest,
  removeConnectedClinic
} from "../../services/database";
import { isValidArray, isValidObject } from "../../services/validators";
import { setErrorStatus } from "../status/actions";
import { getConnectedClinicsImageDownloadUrl } from "../downloads/actions";

export const clinicsActionTypes = {
  SET_CONNECTED_CLINICS: "SET_CONNECTED_CLINICS",
  SELECT_CURRENT_CLINIC: "SELECT_CURRENT_CLINIC",
  PUT_CONNECTED_CLINICS: "PUT_CONNECTED_CLINICS",
  REMOVE_CONNECTED_CLINIC: "REMOVE_CONNECTED_CLINIC",
  REMOVE_CLINICS_REQUEST: "REMOVE_CLINICS_REQUEST"
};

function* setCurrentClinicWorker(action) {
  yield put({
    type: "SET_CURRENT_CLINIC",
    payload: {
      data: action.payload.data
    }
  });
}

function* putConnectedClinicsWorker(action) {
  try {
    yield put({
      type: "SET_CLINICS_LOADING",
      payload: {
        loading: true
      }
    });

    const connectedClinicsListFromClaims = store.getState().auth.data.claims
      ? store.getState().auth.data.claims
      : [];
    const connectedClinicsListFromCollection = isValidObject(
      action.payload.data
    )
      ? Object.keys(action.payload.data)
      : null;
    if (
      (isValidArray(connectedClinicsListFromClaims) &&
        isValidArray(connectedClinicsListFromCollection)) ||
      !isValidArray(connectedClinicsListFromClaims)
    ) {
      if (
        !isValidArray(connectedClinicsListFromClaims) ||
        connectedClinicsListFromClaims.length <
          connectedClinicsListFromCollection.length
      ) {
        yield getCurrentUserClaims(true);
      }

      if (
        isValidArray(connectedClinicsListFromCollection) &&
        connectedClinicsListFromClaims.length >
          connectedClinicsListFromCollection.length
      ) {
        yield getCurrentUserClaims(true);
        yield put({
          type: "SET_CURRENT_CLINIC",
          payload: {
            data: connectedClinicsListFromCollection[0]
          }
        });
      }
    }

    yield put({
      type: "SET_CLINICS",
      payload: {
        data: {
          ...(isValidObject(action.payload.data) ? action.payload.data : {})
        }
      }
    });

    if (isValidObject(action.payload.data)) {
      if (!store.getState().clinics.currentClinic) {
        yield put({
          type: "SET_CURRENT_CLINIC",
          payload: {
            data: Object.keys(action.payload.data)[0]
          }
        });
      }
      getConnectedClinicsImageDownloadUrl(Object.keys(action.payload.data));
    } else {
      yield put({
        type: "SET_CURRENT_CLINIC",
        payload: {
          data: null
        }
      });
    }

    yield put({
      type: "SET_CLINICS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_CLINICS_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

function* removeConnectedClinicWorker(action) {
  try {
    yield removeConnectedClinic(
      store.getState().auth.data.uid,
      action.payload.clinicId
    );
  } catch (error) {
    setErrorStatus(error);
  }
}

function* removeClinicRequestWorker(action) {
  try {
    yield removeClinicRequest(action.payload.requestDocumentId);
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* clinicsWorkers() {
  yield all([
    takeEvery("SELECT_CURRENT_CLINIC", setCurrentClinicWorker),
    takeEvery("PUT_CONNECTED_CLINICS", putConnectedClinicsWorker),
    takeEvery("REMOVE_CONNECTED_CLINIC", removeConnectedClinicWorker),
    takeEvery("REMOVE_CLINICS_REQUEST", removeClinicRequestWorker)
  ]);
}
