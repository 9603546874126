import React, { useState, useEffect } from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import nintoLogo from "../../assets/icons/nintoLogo.svg";
import dropDownIcon from "../../assets/icons/dropdown_arrow.svg";
import clinicPinIcon from "../../assets/icons/clinic_pin.svg";
import clinicPinSelectedIcon from "../../assets/icons/clinic_pin_selected.svg";
import followPatientIcon from "../../assets/icons/Follow_patient.svg";
import followPatientSelectedIcon from "../../assets/icons/Follow_patient_selected.svg";
import notificationIcon from "../../assets/icons/notification.svg";
import notificationSelectedIcon from "../../assets/icons/notification_selected.svg";
import connectionIcon from "../../assets/icons/connection.svg";
import connectionSelectedIcon from "../../assets/icons/connection_selected.svg";
import profileIcon from "../../assets/icons/profile.svg";
import profileSelectedIcon from "../../assets/icons/profile_selected.svg";
import "./ActionsHeader.css";
import { isValidArray } from "../../front-end-global-components/services/validators";

const ActionsHeader = (props) => {
  const [requiredActionPropertiesList, setRequiredActionPropertiesList] =
    useState([]);

  useEffect(() => {
    if (isValidArray(props.requiredActions)) {
      const tempArr = [];
      props.requiredActions.forEach((action) => {
        if (action.value === "PINNED_PATIENTS") {
          tempArr.push({
            defaultIcon: followPatientIcon,
            selectedIcon: followPatientSelectedIcon,
            value: "PINNED_PATIENTS",
            badgeCount: action.badgeCount ? action.badgeCount : null
          });
        } else if (action.value === "NOTIFICATIONS") {
          tempArr.push({
            defaultIcon: notificationIcon,
            selectedIcon: notificationSelectedIcon,
            value: "NOTIFICATIONS",
            badgeCount: action.badgeCount ? action.badgeCount : null
          });
        } else if (action.value === "PROFILE_INFORMATION") {
          tempArr.push({
            defaultIcon: profileIcon,
            selectedIcon: profileSelectedIcon,
            value: "PROFILE_INFORMATION",
            badgeCount: action.badgeCount ? action.badgeCount : null
          });
        } else if (action.value === "CLINIC_PINNED") {
          tempArr.push({
            defaultIcon: clinicPinIcon,
            selectedIcon: clinicPinSelectedIcon,
            value: "CLINIC_PINNED",
            badgeCount: action.badgeCount ? action.badgeCount : null
          });
        } else {
          tempArr.push({
            defaultIcon: connectionIcon,
            selectedIcon: connectionSelectedIcon,
            value: "CLINIC_CONNECTIONS",
            badgeCount: action.badgeCount ? action.badgeCount : null
          });
        }
      });
      setRequiredActionPropertiesList([...tempArr]);
    }
  }, [props.requiredActions]);

  return (
    <>
      <div className="inherit-parent-width actions-header-height flex-justify-content-space-between flex-align-items-center border-bottom-0p5-px-solid-grey ">
        {props.loading === true ? (
          <ActionHeaderSuspense />
        ) : (
          <>
            <section className="padding-medium padding-right-small display-flex justify-content-flex-start flex-align-items-center max-width-50-percentage ">
              <RoundedProfilePicture
                size="medium"
                loading={false}
                onClick={() => {}}
                src={props.picture ? props.picture : nintoLogo}
                data-cy={"pinned-patient-profile-image"}
                editMode={props.editMode === true ? true : false}
                newProfilePictureName={
                  props.newProfilePictureName
                    ? props.newProfilePictureName
                    : null
                }
                onProfilePictureSave={(file) => {
                  if (props.editMode === true) {
                    props.onProfilePictureSave(file);
                  }
                }}
              />
              <div
                className="display-flex align-items-flex-start inherit-parent-width "
                data-cy="action-header-head"
                onClick={() => {
                  if (props.onHeaderClick) {
                    props.onHeaderClick();
                  }
                }}
              >
                <div
                  data-cy="header-current-clinic-name"
                  className="margin-left-medium header-prefix-width header-clinic-name-width"
                >
                  <p
                    data-cy="action-header-title"
                    className=" font-color-secondary font-family-gilroy-regular font-size-medium text-overflow-ellipsis"
                  >
                    {props.title}
                  </p>
                  <p className=" font-color-tertiary font-family-gilroy-regular font-size-small overflow-hidden white-space-no-wrap text-overflow-elilipsis">
                    {props.subTitle}
                  </p>
                </div>
                {props.onHeaderClick && (
                  <img
                    src={dropDownIcon}
                    alt="back-arrow"
                    width="auto"
                    onClick={() => {}}
                    className="cursor-pointer margin-left-small margin-right-default margin-top-default "
                    data-cy="secondary-header-back-arrow"
                  />
                )}
              </div>
            </section>

            <section className="inherit-parent-height display-flex flex-align-items-center padding-right-small ">
              {isValidArray(requiredActionPropertiesList) &&
                requiredActionPropertiesList.map((actionProperties, index) => {
                  return (
                    <ActionIcon
                      currentSelectedAction={props.currentSelectedAction}
                      setCurrentSelectedAction={props.setCurrentSelectedAction}
                      key={`action-icon-${index}`}
                      {...actionProperties}
                    />
                  );
                })}
            </section>
          </>
        )}
      </div>
    </>
  );
};

const ActionIcon = (props) => {
  return (
    <div
      className={"inherit-parent-height display-flex flex-direction-column "}
    >
      <div className="inherit-parent-height flex-place-children-page-center flex-basis-1">
        <div
          className={`
         margin-horizontal-small padding-default width-fit-content border-radius-default flex-place-children-page-center position-relative 
        ${
          props.currentSelectedAction === props.value && "background-color-grey"
        } 
        
      `}
          onClick={() => {
            if (props.currentSelectedAction !== props.value) {
              props.setCurrentSelectedAction(props.value);
            }
          }}
          data-cy={`${props.value}-badge`}
        >
          <img
            src={
              props.currentSelectedAction === props.value
                ? props.selectedIcon
                : props.defaultIcon
            }
            alt={props.value}
            className="action-icon-height"
          />
          {props.badgeCount && (
            <span
              className={`bottom-2-px left-2-px position-absolute text-align-center flex-place-children-page-center font-size-smaller background-color-white border-radius-100-percentage box-shadow-default height-and-width-for-${
                `${props.badgeCount}`.length
              }-digit-number`}
              data-cy={`${props.value}-count`}
            >
              {props.badgeCount >= 100 ? "99+" : props.badgeCount}
            </span>
          )}
        </div>
      </div>
      <div className="padding-horizontal-small inherit-parent-width">
        <div
          className={`height-2-px inherit-parent-width  ${
            props.currentSelectedAction === props.value
              ? "background-color-primary"
              : "background-color-transparent"
          } `}
        />
      </div>
    </div>
  );
};

const ActionHeaderSuspense = () => {
  return (
    <article className="flex-center-children-horizontally inherit-parent-width">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <section className="flex-center-children-vertically ">
          <div className="shimmer padding-larger margin-default border-radius-100-percentage" />
          <div className="width-80percentage">
            <div className="shimmer padding-small margin-default border-radius-default width-80percentage" />
            <div className="shimmer padding-small margin-default border-radius-default width-30percentage" />
          </div>
        </section>
      </main>
    </article>
  );
};

export default ActionsHeader;
