export const Regex = {
  pinCode: /^[1-9][0-9]{5}$/gm,
  aadhaarNumber: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  phoneNumber: /^[6-9]\d{9}$/,
  healthId: /^(?=[a-zA-Z])([a-zA-Z0-9]){2,13}[a-zA-Z]$/i,
  hprPassword:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  // eslint-disable-next-line
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
};
