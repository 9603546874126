import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  getPaginatedNotifications,
  setAllNotificationAsRead,
  setNotificationRead
} from "../../services/database";
import store from "../store/store";
import { isValidObject } from "../../front-end-global-components/services/validators";
import { setErrorStatus } from "../status/actions";
import { updateNotifications } from "./actions";

export const notificationActionTypes = {
  SET_NOTIFICATION: "SET_NOTIFICATION",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  SET_NOTIFICATION_READ: "SET_NOTIFICATION_READ"
};

function* setNotificationWorker(action) {
  try {
    setNotificationLoading(true);

    yield put({
      type: "SET_NOTIFICATION_DATA",
      payload: {
        data: isValidObject(store.getState().notifications.data)
          ? { ...store.getState().notifications.data, ...action.payload.data }
          : action.payload.data
      }
    });

    yield put({
      type: "SET_NOTIFICATION_PAGINATION",
      payload: {
        data:
          store.getState().notifications.pagination !== null &&
          isValidObject(store.getState().notifications.pagination)
            ? {
                ...store.getState().notifications.pagination,
                ...{
                  0: action.payload.lastVisible
                }
              }
            : { 0: action.payload.lastVisible }
      }
    });

    setNotificationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    setNotificationLoading(false);
  }
}
function* updateNotificationWorker(action) {
  try {
    yield setNotificationPaginationLoading(true);
    const profileId = Object.keys(store.getState().profile.data)[0];
    const existingData = store.getState().notifications.data;
    const existingPagination = store.getState().notifications.pagination;

    const lastDocumentPageNumber = action.payload?.lastDocPageNumber
      ? action.payload?.lastDocPageNumber
      : isValidObject(store.getState().notifications.data) &&
        store.getState().notifications?.data[
          Object.keys(store.getState().notifications.data).pop()
        ]?.pageNumber;
    const paginationData = action.payload?.lastDocPageNumber
      ? existingPagination[action.payload.lastDocPageNumber - 1]
      : existingPagination[lastDocumentPageNumber];

    if (
      profileId &&
      paginationData &&
      typeof lastDocumentPageNumber === "number"
    ) {
      const paginationNotification = yield getPaginatedNotifications(
        profileId,
        paginationData,
        lastDocumentPageNumber,
        action.payload?.lastDocPageNumber ? true : false
      );

      if (paginationNotification.notifications) {
        yield put({
          type: "SET_NOTIFICATION_DATA",
          payload: {
            data: {
              ...existingData,
              ...paginationNotification.notifications
            }
          }
        });
      }

      if (!action.payload?.lastDocPageNumber) {
        if (
          isValidObject(paginationNotification.lastVisible) ||
          paginationNotification.lastVisible === "end"
        ) {
          yield put({
            type: "SET_NOTIFICATION_PAGINATION",
            payload: {
              data: {
                ...existingPagination,
                [lastDocumentPageNumber + 1]: paginationNotification.lastVisible
              }
            }
          });
        }
      }
    }

    yield setNotificationPaginationLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setNotificationPaginationLoading(false);
  }
}

function* setNotificationReadWorker(action) {
  try {
    const profileId = Object.keys(store.getState().profile.data)[0];

    if (action.payload.type === "all") {
      let notificationsList = [];
      Object.values(store.getState().notifications.data).filter((data) => {
        return data.read === false && notificationsList.push(data);
      });
      const response = yield setAllNotificationAsRead(notificationsList);
      if (response?.success === true) {
        yield updateNotifications(1);
      }
    } else {
      if (profileId) {
        const response = yield setNotificationRead(
          action.payload.notifications.documentId,
          profileId
        );
        if (response.success === true) {
          yield updateNotifications(action.payload.notifications.pageNumber);
        }
      }
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

export function* notificationsWorkers() {
  yield all([
    takeEvery("SET_NOTIFICATION", setNotificationWorker),
    takeEvery("UPDATE_NOTIFICATION", updateNotificationWorker),
    takeEvery("SET_NOTIFICATION_READ", setNotificationReadWorker)
  ]);
}

function* setNotificationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_LOADING",
    payload: {
      loading: value
    }
  });
}

function* setNotificationPaginationLoading(value) {
  yield put({
    type: "SET_NOTIFICATION_PAGINATION_LOADING",
    payload: {
      loading: value
    }
  });
}
