const authReducer = (
  state = {
    loading: false,
    data: {
      accessToken: null,
      uid: null,
      phoneNumber: null,
      claims: null
    },
    credentials: {
      verificationId: null,
      authCredential: null
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_AUTH_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_AUTH_INFO":
      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken,
          uid: action.payload.uid,
          phoneNumber: action.payload.phoneNumber
        }
      };

    case "SET_AUTH_VERIFICATION_ID":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          verificationId: action.payload.verificationId
        }
      };

    case "SET_AUTH_CREDENTIALS":
      return {
        ...state,
        credentials: {
          ...state.credentials,
          authCredential: action.payload.authCredential
        }
      };

    case "SET_CLAIMS":
      return {
        ...state,
        data: {
          ...state.data,
          claims: action.payload.claims
        }
      };

    case "SET_ACCESSTOKEN":
      return {
        ...state,
        data: {
          ...state.data,
          accessToken: action.payload.accessToken
        }
      };

    case "REMOVE_CREDENTIALS":
      return {
        ...state,
        credentials: {
          verificationId: null,
          authCredential: null
        }
      };

    case "RESET":
      return {
        loading: false,
        data: {
          accessToken: null,
          uid: null,
          phoneNumber: null,
          claims: null
        },
        credentials: {
          verificationId: null,
          authCredential: null
        }
      };

    default:
      return state;
  }
};
export default authReducer;
