import React, { useState, useEffect, useRef } from "react";

import Button from "../../../front-end-global-components/components/Button/Button";
import FileInputBox from "../../../front-end-global-components/components/FileInputBox/FileInputBox";
import { validProfileObject } from "../../../services/utils";
import { setErrorStatus } from "../../../redux/status/actions";
import { editProfileRequest } from "../../../redux/profile/actions";

export default function IdentityProof(props) {
  const [file, setFile] = useState({
    front: null,
    back: null,
    proof: null
  });

  const isFrontFileValid = useRef(false);
  const isBackFileValid = useRef(false);
  const isProofFileValid = useRef(false);

  useEffect(() => {
    if (validProfileObject(props.profile.data)[0]) {
      const docId = Object.keys(props.profile.data);
      const dataDoc = props.profile.data[docId];

      if (dataDoc && dataDoc.aadhar && dataDoc.proof) {
        // Has Data
        setFile({
          ...file,
          front: dataDoc.aadhar ? dataDoc.aadhar.front : null,
          back: dataDoc.aadhar ? dataDoc.aadhar.back : null,
          proof: dataDoc.aadhar ? dataDoc.proof : null
        });
      }
    }
    return () => {};
    // eslint-disable-next-line
  }, [props.profile.data]);

  const onFormInputsChange = (event) => {
    if (event.target.files[0]) {
      const [name] = event.target.name.split("-");
      if (event.target.files[0].size <= 5000000) {
        setFile({
          ...file,
          [name]: event.target.files[0]
        });
      } else {
        setErrorStatus({
          code: "custom",
          message: "Exceeded max size limit of 5MB"
        });
      }
    }
  };

  const onSubmitData = async (event) => {
    event.preventDefault();
    if (
      file.front !== null &&
      typeof file.front !== "string" &&
      file.back !== null &&
      typeof file.back !== "string" &&
      file.proof !== null &&
      typeof file.proof !== "string" &&
      !props.profile.loading
    ) {
      editProfileRequest({
        aadhar: {
          front: file.front,
          back: file.back
        },
        proof: file.proof,
        fileNames: {
          front: file.front.name,
          back: file.back.name,
          proof: file.proof.name
        }
      });
    }
  };

  const isSubmitButtonDisabled = () => {
    if (
      file.front &&
      isFrontFileValid.current === true &&
      file.back &&
      isBackFileValid.current === true &&
      file.proof &&
      isProofFileValid.current === true
    )
      return false;
    return true;
  };

  return (
    <>
      <article className="remaining-body-height overflow-x-scroll flex-start-place-children-vertically ">
        {props.profile.loading === true ? (
          <IdentityProofSuspense />
        ) : (
          <>
            <h3 className="font-weight-normal font-size-large padding-top-larger">
              UPLOAD IDENTITY PROOF
            </h3>
            <form
              className="inherit-parent-width inherit-parent-height grey-abstract-background-image max-width-500px display-flex flex-direction-column padding-medium"
              onChange={(event) => {
                onFormInputsChange(event);
              }}
            >
              <main className="inherit-parent-width inherit-parent-height inherit-parent-height flex-basis-1 ">
                <FileInputBox
                  className="inherit-parent-width margin-vertical-large"
                  name="front"
                  label="Aadhar Front"
                  notes="Accepted formats: .pdf, .jpeg or .png"
                  required
                  accept="image/jpeg,image/png,application/pdf"
                  isValidInput={(foo) => {
                    isFrontFileValid.current = foo;
                  }}
                />

                <FileInputBox
                  className="inherit-parent-width margin-vertical-large"
                  name="back"
                  label="Aadhar Back"
                  notes="Accepted formats: .pdf, .jpeg or .png"
                  required
                  accept="image/jpeg,image/png,application/pdf"
                  isValidInput={(foo) => {
                    isBackFileValid.current = foo;
                  }}
                />

                <FileInputBox
                  className="inherit-parent-width margin-vertical-large"
                  name="proof"
                  label="Medical registration proof"
                  notes="Accepted formats: .pdf, .jpeg or .png"
                  required
                  accept="image/jpeg,image/png,application/pdf"
                  isValidInput={(foo) => {
                    isProofFileValid.current = foo;
                  }}
                />
              </main>

              <footer className="margin-top-larger inherit-parent-width">
                <Button
                  loading={props.profile.loading}
                  id="submit-btn"
                  text="Continue"
                  onClick={onSubmitData}
                  disabled={isSubmitButtonDisabled()}
                  boxShadow={false}
                />
                <button
                  type="button"
                  className=" inherit-parent-width margin-top-larger background-color-transparent font-size-small font-color-secondary font-family-gilroy-regular "
                  onClick={() => {
                    props.navigate("/");
                  }}
                >
                  Skip
                </button>
              </footer>
            </form>
          </>
        )}
      </article>
    </>
  );
}

function IdentityProofSuspense() {
  return (
    <article className="screen-loader-wrapper flex-center-children-horizontally">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger" />
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
        <section className="margin-vertical-larger display-flex-direction-row flex-justify-content-space-between">
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-right-large">
            <div className="padding-default" />
          </div>
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-left-large">
            <div className="padding-default" />
          </div>
        </section>
      </main>
    </article>
  );
}
