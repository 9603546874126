import {
  RecaptchaVerifier,
  signOut,
  updateProfile,
  deleteUser,
  PhoneAuthProvider,
  signInWithCredential
} from "firebase/auth";
import { useEffect, useRef } from "react";
import LogRocket from "logrocket";
import { addAccessToken } from "../redux/authentication/actions";
import { auth } from "./firebase";

//invisible recaptcha verifier
export function reCaptchaVerifier() {
  if (window.Cypress || process.env.REACT_APP_STAGING === "local") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  return new RecaptchaVerifier(
    "recaptcha-placeholder",
    {
      size: "invisible"
    },
    auth
  );
}

export async function otpRequest(phoneNumber) {
  const appVerifier = window.reCaptchaVerifier;
  const authProvider = new PhoneAuthProvider(auth);
  const verificationId = await authProvider.verifyPhoneNumber(
    phoneNumber,
    appVerifier
  );
  return verificationId;
}

export function getCurrentUser() {
  return auth.currentUser;
}

export async function otpValidate(
  loginOtp,
  userName,
  phoneNumber,
  type,
  verificationId
) {
  const authCredentials = PhoneAuthProvider.credential(
    verificationId,
    loginOtp
  );
  return {
    authCredentials: authCredentials,
    userName: userName,
    phoneNumber: phoneNumber,
    type: type
  };
}

export async function signInWithAuthCredentials(authCredentials) {
  const result = await signInWithCredential(auth, authCredentials);
  return {
    accessToken: result.user.accessToken,
    uid: result.user.uid,
    phoneNumber: result.user.phoneNumber
  };
}

//signup using auth credentials
export async function signUpWithAuthCredentials(authCredentials, userName) {
  const response = await signInWithCredential(auth, authCredentials);
  if (response.user.displayName === null) {
    const displayName = {
      clinic: null,
      doctor: userName,
      patient: null
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName)
    });
  } else {
    let otherDisplayNames;
    try {
      otherDisplayNames = JSON.parse(response.user.displayName);
    } catch (error) {
      otherDisplayNames = {};
    }
    const displayName = {
      ...otherDisplayNames,
      doctor: userName
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName)
    });
  }
  return {
    accessToken: response.user.accessToken,
    uid: response.user.uid,
    phoneNumber: response.user.phoneNumber
  };
}

//auth subscriber
export function useAuthSubscriber(onAuthStateChange) {
  const isAuthed = useRef(false);
  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        addAccessToken(accessToken, user.phoneNumber, user.uid);
        if (isAuthed.current !== true) {
          isAuthed.current = true;
          LogRocket.identify(user.uid, {
            phoneNumber: user.phoneNumber
          });
          onAuthStateChange(isAuthed.current);
        }
      } else if (!user && isAuthed.current === true) {
        logout();
        isAuthed.current = false;
        onAuthStateChange(isAuthed.current);
      }
    });
    // eslint-disable-next-line
  }, []);

  return isAuthed.current;
}

//logout subscriber
export const logout = async () => {
  await signOut(auth);
};

export async function deleteUserAccount() {
  await deleteUser(auth.currentUser);
}

export async function getCurrentUserClaims(forceRefresh) {
  if (forceRefresh === true) {
    await auth.currentUser.getIdToken(true);
  }
  const idToken = await auth.currentUser.getIdTokenResult();
  if (
    idToken &&
    idToken.claims &&
    idToken.claims.doctorConnectedClinics &&
    idToken.claims.doctorConnectedClinics.length > 0
  ) {
    return idToken.claims.doctorConnectedClinics;
  } else {
    return null;
  }
}
