import { all, put, takeEvery } from "@redux-saga/core/effects";
import { isValidString } from "../../front-end-global-components/services/validators";
import {
  checkHPRIdExists,
  createHprId,
  sendAadhaarOtpForHPRRegistration,
  sendMobileOtpForHPRRegistration,
  sendOtpForHPRLogin,
  verifyAadhaarOtpForHPRRegistration,
  verifyOtpForHPRLogin
} from "../../services/api";
import { throwError } from "../../services/error";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";
import { setHPRAuthCredentials } from "./actions";

export const abdmActionTypes = {
  SEND_AADHAAR_OTP_FOR_HPR_AUTHENTICATION:
    "SEND_AADHAAR_OTP_FOR_HPR_AUTHENTICATION",
  VERIFY_AADHAAR_OTP_FOR_HPR_AUTHENTICATION:
    "VERIFY_AADHAAR_OTP_FOR_HPR_AUTHENTICATION",
  SEND_MOBILE_OTP_FOR_HPR_AUTHENTICATION:
    "SEND_MOBILE_OTP_FOR_HPR_AUTHENTICATION",
  VERIFY_MOBILE_OTP_FOR_HPR_AUTHENTICATION:
    "VERIFY_MOBILE_OTP_FOR_HPR_AUTHENTICATION",
  CREATE_HPR_ID: "CREATE_HPR_ID",
  ADD_ABDM_AUTH_CREDENTIALS: "ADD_ABDM_AUTH_CREDENTIALS",
  CLEAR_ABDM_AUTH_CREDENTIALS: "CLEAR_ABDM_AUTH_CREDENTIALS",
  RESET_HPR_REGISTRATION_STEP: "RESET_HPR_REGISTRATION_STEP",
  LOGIN_WITH_HPR_ID: "LOGIN_WITH_HPR_ID",
  LOGOUT_HPR_ACCOUNT: "LOGOUT_HPR_ACCOUNT"
};

function* createHPRIdWorker(action) {
  try {
    yield setAbdmLoading(true);
    const response = yield checkHPRIdExists(action.payload.hprId + "@hpr.ndhm");
    let createHprIdResponse;

    if (response?.success === true && !response?.data?.profileExist) {
      // api call to create health Id
      createHprIdResponse = yield createHprId(
        action.payload.data,
        store.getState().abdm.auth.otp.transactionId
      );

      if (
        createHprIdResponse?.success === true &&
        isValidString(createHprIdResponse?.data?.token) &&
        isValidString(createHprIdResponse?.data?.hprId)
      ) {
        //set setHPRId
        yield setHPRId(
          store.getState().abdm.data.hprId
            ? store.getState().abdm.data.hprId
            : createHprIdResponse.data.hprId
        );
        //set accessToken
        yield setAbdmAccessToken(createHprIdResponse.data.token);
        setHPRAuthCredentials(null, null);
      } else {
        setErrorStatus("custom", "HPR ID already exists");
      }
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
  }
}

function* clearABDMAuthCredentialsWorker() {
  try {
    yield put({
      type: "SET_ABDM_OTP_CREDENTIALS",
      payload: {
        requestMethod: null,
        transactionId: null
      }
    });
    if (
      store.getState().abdm.data.hprId &&
      store.getState().abdm.auth.data.accessToken === null
    ) {
      yield setHPRId(null);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* sendAadhaarOtpForHPRAuthWorker(action) {
  try {
    yield setAbdmLoading(true);
    let response;
    switch (action.payload.requestType) {
      case "login":
        response = yield sendOtpForHPRLogin(
          action.payload.requestValue,
          store.getState().auth.data.accessToken
        );
        break;
      case "register":
        response = yield sendAadhaarOtpForHPRRegistration(
          action.payload.requestValue,
          store.getState().auth.data.accessToken
        );
        break;
      default:
        break;
    }

    if (response?.success === true) {
      if (response?.data?.transactionId) {
        yield setHPRAuthCredentials(
          action.payload.requestMethod,
          response.data.transactionId
        );
      }
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* verifyAadhaarOtpForHPRAuthWorker(action) {
  try {
    yield setAbdmLoading(true);
    //API call for otp verify
    let response;
    switch (action.payload.requestType) {
      case "login":
        response = yield verifyOtpForHPRLogin(
          action.payload.otp,
          store.getState().abdm.auth.otp.transactionId,
          store.getState().auth.data.accessToken
        );
        if (response?.success === true) {
          if (response?.data?.token) {
            yield setAbdmAccessToken(response.data.token);
            yield setHPRAuthCredentials(null, null);
            yield put({
              type: "SET_HPR_REGISTRATION_STEP",
              payload: {
                registrationStep: "verifyAadhaar"
              }
            });
          }
        } else if (response?.data?.otpValidation === false) {
          return throwError("custom", "Incorrect OTP");
        } else setErrorStatus(response);
        break;

      case "register":
        response = yield verifyAadhaarOtpForHPRRegistration(
          action.payload.otp,
          store.getState().abdm.auth.otp.transactionId,
          store.getState().auth.data.accessToken
        );
        if (response?.success === true && !!response?.data?.transactionId) {
          yield setHPRAuthCredentials(
            store.getState().abdm.auth.otp.requestMethod,
            response.data.transactionId
          );
          yield put({
            type: "SET_HPR_REGISTRATION_STEP",
            payload: {
              registrationStep: "verifyMobile"
            }
          });
        } else setErrorStatus(response);
        break;

      default:
        throwError("custom", "Please try again");
        break;
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    setErrorStatus(error);
  }
}

function* sendMobileOtpForHPRAuthWorker(action) {
  try {
    yield setAbdmLoading(true);
    const response = yield sendMobileOtpForHPRRegistration(
      store.getState().abdm.auth.otp.transactionId,
      action.payload.phoneNumber
    );
    if (
      response.success === true &&
      response.data.mobileVerified === true &&
      response.data.aadhaarDetails
    ) {
      yield put({
        type: "SET_HPR_REGISTRATION_STEP",
        payload: {
          registrationStep: "createHPR"
        }
      });
      yield put({
        type: "SET_IS_MOBILE_VERIFIED",
        payload: {
          isMobileVerified: response.data.mobileVerified
        }
      });
      yield put({
        type: "SET_AADHAAR_DETAILS",
        payload: {
          aadhaarDetails: response.data.aadhaarDetails
        }
      });
    }

    if (
      response.success === true &&
      response.data.mobileVerified === false &&
      response.data.transactionId
    ) {
      yield put({
        type: "SET_IS_MOBILE_VERIFIED",
        payload: {
          isMobileVerified: response.data.mobileVerified
        }
      });
    }

    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    yield setErrorStatus(error);
  }
}

function* verifyMobileOtpForHPRAuthWorker(action) {
  try {
    yield setAbdmLoading(true);
    let response;
    switch (action.payload.requestType) {
      case "register":
        response = yield verifyAadhaarOtpForHPRRegistration(
          store.getState().abdm.auth.otp.transactionId,
          action.payload.otp
        );
        if (response?.success === true && !!response?.data?.transactionId) {
          yield setHPRAuthCredentials(
            store.getState().abdm.auth.otp.requestMethod,
            response.data.transactionId
          );
          yield put({
            type: "SET_HPR_REGISTRATION_STEP",
            payload: {
              registrationStep: "createHPR"
            }
          });
        } else {
          setErrorStatus(response);
        }
        break;

      default:
        throwError("custom", "Please try again");
        break;
    }
    yield setAbdmLoading(false);
  } catch (error) {
    yield setAbdmLoading(false);
    setErrorStatus(error);
  }
}

function* setHPRAuthCredentialsWorker(action) {
  try {
    yield put({
      type: "SET_ABDM_OTP_CREDENTIALS",
      payload: {
        requestMethod: action.payload.requestMethod,
        transactionId: action.payload.transactionId
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}
function* resetHPRRegistrationStepWorker(action) {
  try {
    yield put({
      type: "SET_HPR_REGISTRATION_STEP",
      payload: {
        registrationStep: "verifyAadhaar",
        isMobileVerified: null
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

// function* clearHealthIdExistsStatusWorker(action) {
//   try {
//     yield setHprIdExist(null);
//   } catch (error) {
//     setErrorStatus(error);
//   }
// }

function* logoutHPRWorker() {
  yield put({
    type: "LOGOUT_HPR"
  });
}

export default function* abdmWatcher() {
  yield all([
    takeEvery("CREATE_HPR_ID", createHPRIdWorker),
    takeEvery("LOGOUT_HPR_ACCOUNT", logoutHPRWorker),
    takeEvery("ADD_ABDM_AUTH_CREDENTIALS", setHPRAuthCredentialsWorker),
    takeEvery(
      "SEND_AADHAAR_OTP_FOR_HPR_AUTHENTICATION",
      sendAadhaarOtpForHPRAuthWorker
    ),
    takeEvery(
      "VERIFY_AADHAAR_OTP_FOR_HPR_AUTHENTICATION",
      verifyAadhaarOtpForHPRAuthWorker
    ),
    takeEvery("RESET_HPR_REGISTRATION_STEP", resetHPRRegistrationStepWorker),

    takeEvery(
      "SEND_MOBILE_OTP_FOR_HPR_AUTHENTICATION",
      sendMobileOtpForHPRAuthWorker
    ),
    takeEvery(
      "VERIFY_MOBILE_OTP_FOR_HPR_AUTHENTICATION",
      verifyMobileOtpForHPRAuthWorker
    ),
    // takeEvery("CLEAR_HEALTH_ID_EXISTS_STATUS", clearHealthIdExistsStatusWorker),
    takeEvery("CLEAR_ABDM_AUTH_CREDENTIALS", clearABDMAuthCredentialsWorker)
  ]);
}

/**
 * abdm reducer action dispatch
 */
function* setAbdmAccessToken(abdmAccessToken) {
  yield put({
    type: "SET_ABDM_ACCESS_TOKEN",
    payload: {
      abdmAccessToken: abdmAccessToken
    }
  });
}

function* setHPRId(HPRId) {
  yield put({
    type: "SET_HPR_ID",
    payload: {
      hprId: HPRId
    }
  });
}

function* setAbdmLoading(loadingState) {
  if (store.getState().abdm.loading !== loadingState) {
    yield put({
      type: "SET_ABDM_LOADING",
      payload: {
        loading: loadingState
      }
    });
  }
}
