import { all, put, takeEvery } from "@redux-saga/core/effects";
import { createProfile, editProfile } from "../../services/database";
import store from "../store/store";
import { isValidObject } from "../../services/validators";
import { uploadProfilePicture } from "../../services/storage";
import { bucketNames, validProfileObject } from "../../services/utils";
import { throwError } from "../../services/error";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { getUserImageDownloadUrl } from "../downloads/actions";

export const profileActionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
  CREATE_PROFILE: "CREATE_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
  SET_PROFILE_PICTURE: "SET_PROFILE_PICTURE",
  REMOVE_ACCOUNT_PROFILES: "REMOVE_ACCOUNT_PROFILES",
  SET_PROFILE_DATA: "SET_PROFILE_DATA"
};

function* setProfilesWorker(action) {
  try {
    if (isValidObject(action.payload.profiles)) {
      if (action.payload.firstUpdate === true) {
        getUserImageDownloadUrl();
      }
    }

    const doctorId = Object.keys(action.payload.profiles)[0];

    if (typeof doctorId === "string") {
      yield put({
        type: "SET_PROFILES",
        payload: {
          [doctorId]: {
            ...action.payload.profiles[doctorId]
          }
        }
      });
    } else {
      yield put({
        type: "SET_PROFILES",
        payload: action.payload.profiles
      });
    }
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: false
      }
    });
    if (typeof store.getState().profile.currentField === "string") {
      yield put({
        type: "SET_CURRENT_FIELD",
        payload: {
          currentField: null
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* removeProfilesWorker() {
  yield put({
    type: "REMOVE_PROFILES"
  });
}

function* createProfileRequestWorker(action) {
  try {
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: true
      }
    });
    const uid = store.getState().auth.data.uid;
    yield createProfile(uid);

    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

function* editProfileRequestWorker(action) {
  try {
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: true
      }
    });
    if (typeof action.payload.currentField === "string") {
      yield put({
        type: "SET_CURRENT_FIELD",
        payload: {
          currentField: action.payload.currentField
        }
      });
    }

    if (validProfileObject(store.getState().profile.data)) {
      const [docId] = Object.keys(store.getState().profile.data);
      yield editProfile(
        docId,
        action.payload.data,
        store.getState().clinics.data
      );
    } else {
      throwError("custom", "Unable to Update Profile");
    }
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_PROFILE_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

function* setProfilePictureWorker(action) {
  try {
    if (
      isValidObject(store.getState().profile.data) &&
      isValidObject(store.getState().auth.data) &&
      action.payload.profilePicture
    ) {
      const file = action.payload.profilePicture;
      yield uploadProfilePicture(file);
      const docProfileUid = store.getState().auth.data.uid;
      const profilePicture = `gs://${bucketNames.nintoProfilePictures}/doctors/${docProfileUid}.png`;
      yield editProfile(
        docProfileUid,
        {
          profilePicture: profilePicture
        },
        store.getState().clinics.data
      );
      getUserImageDownloadUrl(true);
      setSuccessStatus("Profile picture updated Successfully");
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setProfileDataWorker(action) {
  try {
    yield put({
      type: "SET_PROFILES",
      payload: action.payload.data
    });
  } catch (error) {
    console.error(error);
  }
}

export function* profileWatcher() {
  yield all([
    takeEvery("CREATE_PROFILE", createProfileRequestWorker),
    takeEvery("EDIT_PROFILE", editProfileRequestWorker),
    takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker),
    takeEvery("SET_PROFILE_PICTURE", setProfilePictureWorker),
    takeEvery("REMOVE_ACCOUNT_PROFILES", removeProfilesWorker),
    takeEvery("SET_PROFILE_DATA", setProfileDataWorker)
  ]);
}
