import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../front-end-global-components/components/Button/Button";
import Header from "../../front-end-global-components/components/Header/Header";
import errorBoundaryFallBack from "../../assets/images/errorBoundaryFallBack.svg";
const Forms = (props) => {
  const { documentId } = useParams();

  return (
    <section className=" inherit-parent-height inherit-parent-width ">
      <Header
        title={
          props.documents.data?.[documentId]?.name
            ? props.documents.data[documentId].name
            : "Form"
        }
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        supportIconOnClick={() => props.navigate("/queries")}
      />
      {!!props.documents.data?.[documentId] &&
      Array.isArray(props.documents.data?.[documentId]?.fields) ? (
        <section
          className=" remaining-body-height padding-left-large padding-right-large overflow-y-auto padding-top-medium "
          data-cy="view-filled-form"
        >
          {" "}
          {props.documents.data[documentId].fields.map((field) => (
            <React.Fragment key={field.name}>
              <InputField name={field.name} value={field.value} />
            </React.Fragment>
          ))}
        </section>
      ) : (
        <FormNotFound navigate={props.navigate} />
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  documents: state.documents
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);

const getDate = (timestamp) => {
  if (typeof timestamp !== "number") return;
  return `${new Date(timestamp).getDate()}-${
    new Date(timestamp).getMonth() + 1
  }-${new Date(timestamp).getFullYear()}`;
};

const InputField = (field) => {
  return (
    <section className=" font-color-secondary font-family-gilroy-regular inherit-parent-width padding-top-medium padding-bottom-medium margin-horizontal-auto max-width-588px ">
      <div className=" letter-spacing-4-percentage font-size-smaller padding-bottom-small text-transform-uppercase font-weight-400 ">
        {field.name}
      </div>
      <div className=" letter-spacing-4-percentage font-size-medium padding-top-small text-transform-capitalize font-weight-400 ">
        {!!field?.value
          ? field.name === "date of birth" || field.type === "timestamp"
            ? getDate(field.value)
            : Array.isArray(field.value) && field.value.length > 0
            ? field.value.map(
                (val, index) =>
                  val + `${index !== field.value.length - 1 ? ", " : ""}`
              )
            : field.value
          : "-"}
      </div>
    </section>
  );
};

function FormNotFound(props) {
  return (
    <section className=" remaining-body-height max-width-588px margin-horizontal-auto overflow-hidden ">
      <section className=" inherit-parent-height inherit-parent-width flex-place-children-page-center padding-larger">
        <main className="flex-place-children-page-center">
          <img src={errorBoundaryFallBack} alt="querySubmittedSuccess" />
          <h1 className="font-weight-normal font-family-gilroy-bold font-size-medium font-color-secondary padding-top-large padding-bottom-large">
            Form not found
          </h1>
          <p className="font-family-gilroy-medium font-size-medium font-color-tertiary text-align-center">
            Please try again later. If the problem persists, please contact
            customer support.
          </p>
          <div className=" width-33-percentage ">
            <Button
              buttonKey="document-retry-button"
              data-cy="button"
              type="submit"
              text="Okay"
              className="inherit-parent-width margin-top-larger"
              onClick={(_) => {
                props.navigate("/");
              }}
              boxShadow={false}
            />
          </div>
        </main>
      </section>
    </section>
  );
}
