import { put, takeEvery, all } from "redux-saga/effects";
import { checkAccountAvailability } from "../../services/api";
import {
  otpValidate,
  logout,
  otpRequest,
  deleteUserAccount,
  signInWithAuthCredentials,
  signUpWithAuthCredentials
} from "../../services/authentication";
import store from "../store/store";
import { throwError } from "../../services/error";
import { setErrorStatus } from "../status/actions";
import { requestLogin, requestSignUp } from "./actions";
import { createProfileRequest } from "../profile/actions";

export const authActionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  CLEAR_CREDENTIALS: "CLEAR_CREDENTIALS",
  ADD_ACCESS_TOKEN: "ADD_ACCESS_TOKEN"
};

function* signUpRequestWorker(action) {
  try {
    if (store.getState().auth.data.accessToken === null) {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: true
        }
      });
      const result = yield signUpWithAuthCredentials(
        store.getState().auth.credentials.authCredential,
        action.payload.userName,
        action.payload.phoneNumber
      );
      yield put({
        type: "SET_AUTH_INFO",
        payload: {
          accessToken: result.accessToken,
          uid: result.uid,
          phoneNumber: result.phoneNumber
        }
      });

      createProfileRequest();

      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
    } else {
      throw throwError("custom", "User already logged in");
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

export function* signUpResponseWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        accessToken: action.payload.accessToken,
        uid: action.payload.uid,
        phoneNumber: action.payload.phoneNumber
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* loginRequestWorker(action) {
  try {
    if (store.getState().auth.data.accessToken === null) {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: true
        }
      });
      const result = yield signInWithAuthCredentials(
        store.getState().auth.credentials.authCredential
      );
      yield put({
        type: "SET_AUTH_INFO",
        payload: {
          accessToken: result.accessToken,
          uid: result.uid,
          phoneNumber: result.phoneNumber
        }
      });
    } else {
      throw throwError("custom", "User already logged in");
    }
    yield put({
      type: "REMOVE_CREDENTIALS"
    });
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

export function* loginResponseWorker(action) {
  yield put({
    type: "SET_AUTH_INFO",
    payload: {
      accessToken: action.payload.accessToken,
      uid: action.payload.uid,
      phoneNumber: action.payload.phoneNumber,
      claims: action.payload.claims
    }
  });
  yield put({
    type: "REMOVE_CREDENTIALS"
  });
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: false
    }
  });
}

function* logoutRequestWorker(action) {
  try {
    if (store.getState().auth.data.accessToken !== null) {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: true
        }
      });
      yield logout();
      yield put({
        type: "RESET"
      });
      indexedDB.databases().then((dataBase) => {
        dataBase.forEach((dbName) => {
          indexedDB.deleteDatabase(dbName.name);
        });
      });
    }
  } catch (error) {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield setErrorStatus(error);
  }
}

export function* logoutResponseWorker() {
  yield put({
    type: "RESET"
  });
}

function* otpRequestWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });

    yield put({
      type: "REMOVE_CREDENTIALS"
    });

    if (
      typeof action.payload.phoneNumber !== "string" ||
      (action.payload.phoneNumber.length < 13 &&
        action.payload.phoneNumber.length > 15)
    ) {
      throw throwError("custom", "Please enter a valid phone number");
    } else if (isNaN(action.payload.phoneNumber)) {
      throw throwError("custom", "Please enter a valid phone number");
    }

    if (action.payload.type === "signUp") {
      if (action.payload.phoneNumber.length !== 13) {
        throwError("custom", "Please enter a valid phone number");
      }

      const response = yield checkAccountAvailability(
        action.payload.phoneNumber
      );

      if (
        response.data.phoneNumberExists === true &&
        response.data.profileExists === true
      ) {
        throw throwError("custom", "User already registered. Please login");
      } else {
        const verificationId = yield otpRequest(action.payload.phoneNumber);
        yield put({
          type: "SET_AUTH_VERIFICATION_ID",
          payload: {
            verificationId: verificationId
          }
        });
        yield put({
          type: "SET_AUTH_LOADING",
          payload: {
            loading: false
          }
        });
      }
    } else if (action.payload.type === "login") {
      if (action.payload.phoneNumber.length !== 13) {
        throw throwError("custom", "Please Enter a valid phone number");
      }

      const response = yield checkAccountAvailability(
        action.payload.phoneNumber
      );

      if (response.data.profileExists !== true) {
        throw throwError("custom", "User does not exist. Please signup");
      } else {
        const verificationId = yield otpRequest(action.payload.phoneNumber);
        yield put({
          type: "SET_AUTH_VERIFICATION_ID",
          payload: {
            verificationId: verificationId
          }
        });
        yield put({
          type: "SET_AUTH_LOADING",
          payload: {
            loading: false
          }
        });
      }
    }
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield setErrorStatus(error);
  }
}

export function* verifyOtpWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });

    const validationResult = yield otpValidate(
      action.payload.loginOtp,
      action.payload.userName,
      action.payload.phoneNumber,
      action.payload.type,
      store.getState().auth.credentials.verificationId
    );

    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: validationResult.authCredentials
      }
    });

    if (validationResult.type === "signUp") {
      requestSignUp(validationResult.userName, validationResult.phoneNumber);
    } else {
      requestLogin();
    }
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield setErrorStatus(error);
  }
}

export function* verifyOtpResponseWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: action.payload.authCredential
      }
    });
    if (action.payload.type === "signUp") {
      requestSignUp(action.payload.userName, action.payload.phoneNumber);
    } else {
      requestLogin();
    }
  } catch (error) {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield setErrorStatus(error);
  }
}

export function* deleteAccountWorker() {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    yield deleteUserAccount();
    yield put({
      type: "RESET"
    });
  } catch (error) {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
    yield setErrorStatus(error);
  }
}

function* clearCredentialsWorker() {
  yield put({
    type: "REMOVE_CREDENTIALS"
  });
}

export function* addAccessTokenWorker(action) {
  yield put({
    type: "SET_AUTH_INFO",
    payload: {
      accessToken: action.payload.accessToken,
      uid: action.payload.uid,
      phoneNumber: action.payload.phoneNumber
    }
  });
}

export function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", otpRequestWorker),
    takeEvery("VERIFY_OTP", verifyOtpWorker),
    takeEvery("LOGIN", loginRequestWorker),
    takeEvery("LOGOUT", logoutRequestWorker),
    takeEvery("SIGNUP", signUpRequestWorker),
    takeEvery("DELETE_ACCOUNT", deleteAccountWorker),
    takeEvery("CLEAR_CREDENTIALS", clearCredentialsWorker),
    takeEvery("ADD_ACCESS_TOKEN", addAccessTokenWorker)
  ]);
}
