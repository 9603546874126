import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import SymptomsRenderer from "../../front-end-global-components/components/SymptomRenderer/SymptomRenderer";
// import TextBox from "../../components/textInputBox/TextInputBox";
import {
  isValidArray,
  isValidObject,
  isValidString
} from "../../services/validators";
import { validProfileObject, validValue } from "../../services/utils";
import AudioPlayer from "../../front-end-global-components/components/AudioPlayer/AudioPlayer";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
import "../../front-end-global-components/design-system.css";
import "./ViewSymptom.css";
import { InputSlider } from "../../front-end-global-components/components/InputSlider/InputSlider";
import { getDocumentDownloadUrl } from "../../redux/downloads/actions";
import {
  getDocumentsData,
  selectCurrentDocument
} from "../../redux/documents/actions";

const ViewSymptom = (props) => {
  let { documentId } = useParams();
  const [show, setShow] = useState("describe");
  // eslint-disable-next-line no-unused-vars
  const [rerender, setRerender] = useState("");

  const [formData, setFormData] = useState({
    date: new Date().toISOString().split("T")[0],
    time: new Date().toTimeString().slice(0, 5),
    pid: null,
    audio: "",
    timestamp: +new Date(),
    description: "",
    severityScale: 1,
    location: {
      front: [],
      back: []
    },
    type: "symptom"
  });

  const addPicturesElementRef = useRef(null);
  const addPicturesSectionWidthRef = useRef(null);
  const [selectedFileToViewIndex, setSelectedFileToViewIndex] = useState(null);
  const [addedFiles, setAddedFiles] = useState([]);
  // "https://i.pinimg.com/564x/9b/76/75/9b767505f5a5df3df348a898ba4ae8bb.jpg",
  // "https://cdn.mos.cms.futurecdn.net/p3SwYkviLmmEQ6vx54qAHB-1200-80.jpg",
  // "https://cdn.sanity.io/images/dm4o0ui7/production/ab8622774dfd8bc6b2107656cc1d648ff48279b3-1200x600.png"

  const showSymptomScreen = () => {
    if (selectedFileToViewIndex === null) return true;
    return false;
  };

  const showImageFullViewComp = () => {
    if (
      selectedFileToViewIndex !== null &&
      isValidArray(addedFiles) &&
      addedFiles[selectedFileToViewIndex]
    )
      return true;
    return false;
  };

  const imgSrc = (file) => {
    if (typeof file === "string") {
      return file;
    } else if (file instanceof File) {
      return window.URL.createObjectURL(file);
    }
  };

  //Form onchange hanlder to save form data in state
  const formOnChangeHandler = (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    addPicturesSectionWidthRef.current =
      addPicturesElementRef.current &&
      addPicturesElementRef.current.offsetWidth;
    if (
      isValidObject(props.downloads.data) &&
      isValidObject(props.downloads.data.symptoms) &&
      isValidObject(props.downloads.data.symptoms[documentId]) &&
      isValidArray(props.downloads.data.symptoms[documentId].images)
    ) {
      setAddedFiles([...props.downloads.data.symptoms[documentId].images]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.downloads.data), documentId, props.documents.data]);

  useEffect(() => {
    if (validProfileObject(props.profile.data)[0] === true) {
      // documentType.current = window.location.pathname.split("/")[1];
      if (isValidObject(props.documents.data)) {
        const documentsIds = Object.keys(props.documents.data);
        if (documentsIds.indexOf(documentId) >= 0) {
          if (props.documents.currentDocument !== documentId) {
            props.selectCurrentDocument(documentId);
          }
        } else {
          props.getDocumentsData({ documentDocId: documentId });
          if (props.documents.currentDocument !== documentId) {
            props.selectCurrentDocument(documentId);
          }
        }
      } else {
        props.getDocumentsData({ documentDocId: documentId });
        if (props.documents.currentDocument !== documentId) {
          props.selectCurrentDocument(documentId);
        }
      }
    }
    // eslint-disable-next-line
  }, [documentId, props.profile.data, props.auth.data.accessToken]);

  useEffect(() => {
    if (
      documentId !== undefined &&
      documentId !== null &&
      isValidObject(props.documents.data)
    ) {
      const symptomDocument = props.documents.data[documentId];
      if (isValidObject(symptomDocument)) {
        // const timestamp = symptomDocument.timestamp * 1000;
        let obj = {
          documentId: symptomDocument.documentId,
          timestamp: symptomDocument.timestamp,
          pid: symptomDocument.to.id,
          audio: symptomDocument.audio,
          description: symptomDocument.description,
          severityScale: symptomDocument.severityScale,
          location: symptomDocument.location,
          date: new Date(symptomDocument.timestamp - 172800000)
            .toISOString()
            .split("T")[0],
          time: new Date(symptomDocument.timestamp - 172800000)
            .toTimeString()
            .slice(0, 5)
        };
        setFormData(obj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.documents.data), documentId]);

  useEffect(() => {
    if (
      isValidObject(props.downloads.data) &&
      typeof props.documents.currentDocument === "string"
    ) {
      const audioUrl = validValue(() => {
        return props.documents.data[props.documents.currentDocument].url.audio;
      });
      const audioDownloadUrl = validValue(() => {
        return props.downloads.data["symptoms"][documentId].downloadUrl;
      });

      if (audioUrl) {
        if (!audioDownloadUrl) {
          props.getDocumentDownloadUrl("symptoms", documentId);
        }
      }

      const imagesUrl = validValue(() => {
        return props.documents.data[documentId].url.images;
      });
      const imagesDownloadUrl = validValue(() => {
        return props.downloads.data["symptoms"][documentId].images;
      });
      if (isValidArray(imagesUrl)) {
        if (
          !isValidArray(imagesDownloadUrl) ||
          (isValidArray(imagesUrl) &&
            imagesUrl.length !== imagesDownloadUrl.length)
        ) {
          props.getDocumentDownloadUrl("symptoms", documentId);
        }
      }
    }
    // eslint-disable-next-line
  }, [props.documents.currentDocument, props.downloads.data]);

  const symptomsAudioDownloadUrl = () => {
    if (
      isValidObject(props.documents.data) &&
      typeof props.documents.currentDocument === "string"
    ) {
      // Check is Download URL already Exist
      // "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
      if (
        isValidObject(props.downloads.data) &&
        isValidObject(props.downloads.data["symptoms"]) &&
        isValidObject(props.downloads.data["symptoms"][documentId]) &&
        typeof props.downloads.data["symptoms"][documentId].downloadUrl ===
          "string"
      ) {
        return props.downloads.data["symptoms"][documentId].downloadUrl;
      } else {
        return null;
      }
      // props.getDocumentDownloadUrl("symptoms");
    } else {
      return null;
    }
  };

  const shouldShowSuspense = () => {
    if (props.documents.loading === true) {
      return true;
    } else {
      return false;
    }
  };

  const patientGender = () => {
    const _gender = validValue(() => {
      return props.patients.currentPatient[
        Object.keys(props.patients.currentPatient)[0]
      ].private.gender;
    });
    if (_gender) {
      return _gender;
    } else {
      return "male";
    }
  };

  return (
    <>
      {showSymptomScreen() === true ? (
        <div className="inherit-parent-height">
          <Header
            title="Symptom"
            backButtonOnClick={() => props.navigate(-1)}
            supportIconOnClick={() => {
              props.navigate("/queries");
            }}
          />
          {shouldShowSuspense() === true ? (
            <ViewSymptomSuspense />
          ) : (
            //remaining-body-height overflow-x-scroll
            <div
              className={`padding-left-large remaining-body-height overflow-x-scroll padding-right-large padding-top-default  max-width-588px margin-horizontal-auto inherit-parent-width ${
                show === "locateSymptom" ? "overflow-hidden" : ""
              } `}
              data-cy="documents-view-screen"
            >
              {(isValidArray(formData.location?.front) ||
                isValidArray(formData.location?.back)) && (
                <div className="flex-center-children-horizontally">
                  <div className="flex-justify-content-space-between font-size-small border-radius-default box-shadow-default padding-small background-white">
                    <React.Fragment>
                      <span
                        className={`font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-large padding-left-large ${
                          show === "locateSymptom"
                            ? "font-color-secondary background-color-grey"
                            : "font-color-tertiary"
                        }`}
                        onClick={() => {
                          setShow("locateSymptom");
                        }}
                        data-cy="location-btn"
                      >
                        Location
                      </span>

                      <span
                        className={`font-family-gilroy-medium border-radius-default padding-top-small padding-bottom-small padding-right-medium padding-left-medium ${
                          show === "describe"
                            ? "font-color-secondary background-color-grey"
                            : "font-color-tertiary"
                        }`}
                        onClick={() => {
                          setShow("describe");
                        }}
                        data-cy="description-btn"
                      >
                        Description
                      </span>
                    </React.Fragment>
                  </div>
                </div>
              )}
              {show === "locateSymptom" ? (
                <div className="inherit-parent-height">
                  <SymptomsRenderer
                    onExport={(locations) => {
                      setFormData({ ...formData, location: locations });
                    }}
                    locations={formData.location}
                    readOnly={true}
                    gender={patientGender()}
                  />
                </div>
              ) : (
                <form
                  onChange={(event) => {
                    formOnChangeHandler(event);
                  }}
                  className=""
                  data-cy="symptom-document-form"
                >
                  <InputBox
                    data-cy="date"
                    className="inherit-parent-width border-radius-default margin-top-large"
                    type="text"
                    name="date"
                    value={formData.date}
                    disabled={true}
                    label="date"
                  />
                  <InputBox
                    data-cy="time"
                    className="inherit-parent-width border-radius-default margin-top-large"
                    type="text"
                    name="time"
                    value={formData.time}
                    label="time"
                    disabled
                  />
                  {typeof formData.description === "string" &&
                    formData.description.trim().length > 0 && (
                      <TextArea
                        data-cy="description"
                        name="description"
                        label="Describe your symptom"
                        className="margin-top-large"
                        defaultValue={formData.description}
                        disabled
                      />
                    )}
                  <InputSlider
                    label="SEVERITY SCALE"
                    name="severityScale"
                    min={1}
                    value={formData.severityScale}
                    max={10}
                    className="margin-top-large"
                    disabled
                  />
                  {typeof props.documents.currentDocument === "string" &&
                    isValidObject(props.documents.data) &&
                    isValidObject(props.documents.data[documentId]) &&
                    isValidObject(props.documents.data[documentId].url) &&
                    isValidString(
                      props.documents.data[documentId].url.audio
                    ) && (
                      <div className="flex-justify-content-space-between margin-top-larger">
                        <AudioPlayer
                          src={symptomsAudioDownloadUrl()}
                          getDownloadURL={() => {
                            props.getDocumentDownloadUrl(
                              "symptoms",
                              documentId
                            );
                          }}
                        />
                      </div>
                    )}
                </form>
              )}

              <section
                ref={addPicturesElementRef}
                className="inherit-parent-width"
              >
                {show !== "locateSymptom" && (
                  <>
                    {props.downloads.loading === true ? (
                      <SymptomImageSuspense
                        width={`${
                          !!addPicturesSectionWidthRef.current
                            ? addPicturesSectionWidthRef.current / 3
                            : 80
                        }px`}
                        height={`${
                          !!addPicturesSectionWidthRef.current
                            ? addPicturesSectionWidthRef.current / 3
                            : 80
                        }px`}
                      />
                    ) : (
                      <>
                        {isValidArray(addedFiles) && (
                          <>
                            <h6 className="font-weight-normal font-family-gilroy-regular font-color-secondary font-size-small margin-top-larger margin-bottom-default">
                              PICTURES
                            </h6>
                            <ul className="inherit-parent-width flex-direction-row list-style-type-none column-gap-3-percentage">
                              {addedFiles.map((file, index) => (
                                <li
                                  className="border-radius-default"
                                  key={`symptom-image-key-${index}`}
                                  style={{
                                    width: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`,
                                    height: `${
                                      addPicturesSectionWidthRef.current
                                        ? addPicturesSectionWidthRef.current / 3
                                        : 80
                                    }px`
                                  }}
                                >
                                  <img
                                    src={imgSrc(file)}
                                    alt="symptom"
                                    data-cy={`symptom-image-${index}`}
                                    className="inherit-parent-height border-radius-default inherit-parent-width object-fit-cover border-solid-1px-grey"
                                    onClick={() => {
                                      setSelectedFileToViewIndex(index);
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </div>
      ) : (
        showImageFullViewComp() === true && (
          <>
            <div
              data-cy="image-full-view-comp"
              className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black"
            >
              <div className="flex-center-children-horizontally flex-direction-column flex-grow-1 max-height-90-percentage">
                <img
                  src={imgSrc(addedFiles[selectedFileToViewIndex])}
                  alt="symptom"
                  className="inherit-parent-height max-height-90-percentage object-fit-contain"
                />
              </div>
              <footer className="padding-larger inherit-parent-width flex-align-items-center">
                <button
                  data-cy="image-full-view-comp-close-btn"
                  type="submit"
                  variant="secondary"
                  className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
                  onClick={() => {
                    setSelectedFileToViewIndex(null);
                  }}
                >
                  Close
                </button>
              </footer>
            </div>
          </>
        )
      )}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
    documents: state.documents,
    downloads: state.downloads,
    patients: state.patients
  };
};

const mapDispatchToProps = function () {
  return {
    selectCurrentDocument: (documentDocId) =>
      selectCurrentDocument(documentDocId),
    getDocumentsData: (documentDocId, patientId) =>
      getDocumentsData(documentDocId, patientId),
    getDocumentDownloadUrl: (documentType, documentId) =>
      getDocumentDownloadUrl(documentType, documentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSymptom);

// TODO
function ViewSymptomSuspense() {
  return (
    <article
      data-cy="view-document-suspense"
      className="screen-loader-wrapper inherit-parent-width  flex-center-children-horizontally"
    >
      <main className="inherit-parent-width padding-medium ">
        <section className="inherit-parent-width flex-place-children-page-center height-10vh">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
        <section className="shimmer height-60vh border-radius-default" />
        <section className="inherit-parent-width flex-place-children-page-center height-10vh ">
          <div className="shimmer padding-larger margin-default border-radius-default width-80percentage" />
        </section>
      </main>
    </article>
  );
}

function SymptomImageSuspense(props) {
  return (
    <article
      data-cy="symptom-images-suspense"
      className="screen-loader-wrapper inherit-parent-width"
    >
      <div className="shimmer border-radius-default padding-default width-40-percentage margin-bottom-medium" />
      <ul className="inherit-parent-width flex-direction-row list-style-type-none column-gap-3-percentage">
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
        <li
          className="shimmer border-radius-default"
          style={{
            width: `${props.width}px`,
            height: `${props.height}px`
          }}
        />
      </ul>
    </article>
  );
}
