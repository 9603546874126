import store from "../store/store";
import { abdmActionTypes } from "./saga";
/**
 * @module AbdmSaga
 */

/**
 * create HPR
 * @param {object} data
 */
export function createHPRId(data) {
  store.dispatch({
    type: abdmActionTypes.CREATE_HPR_ID,
    payload: {
      data: data
    }
  });
}

/**
 * send aadhaar OTP for HPR auth
 * @param {string} requestType
 * @param {string} requestMethod
 * @param {string} requestValue
 */
export function sendAadhaarOtpForHPRAuthentication(
  requestType,
  requestMethod,
  requestValue
) {
  store.dispatch({
    type: abdmActionTypes.SEND_AADHAAR_OTP_FOR_HPR_AUTHENTICATION,
    payload: {
      requestType: requestType,
      requestMethod: requestMethod,
      requestValue: requestValue
    }
  });
}

/**
 * verify aadhaar OTP for HPR auth
 * @param {string} requestType
 * @param {string} requestValue
 * @param {string} otp
 */
export function verifyAadhaarOtpHPRAuthentication(
  requestType,
  requestValue,
  otp
) {
  store.dispatch({
    type: abdmActionTypes.VERIFY_AADHAAR_OTP_FOR_HPR_AUTHENTICATION,
    payload: {
      requestType: requestType,
      requestValue: requestValue,
      otp: otp
    }
  });
}
/**
 * send mobile OTP for HPR auth
 * @param {string} phoneNumber
 */
export function sendMobileOtpForHPRAuthentication(phoneNumber) {
  store.dispatch({
    type: abdmActionTypes.SEND_MOBILE_OTP_FOR_HPR_AUTHENTICATION,
    payload: {
      phoneNumber: phoneNumber
    }
  });
}
/**
 * verify mobile OTP for HPR auth
 * @param {string} otp
 * @param {string} requestType
 */
export function verifyMobileOtpHPRAuthentication(otp, requestType) {
  store.dispatch({
    type: abdmActionTypes.VERIFY_MOBILE_OTP_FOR_HPR_AUTHENTICATION,
    payload: {
      requestType: requestType,
      otp: otp
    }
  });
}
/**
 * set HPR auth Credentials
 * @param {string} requestMethod
 * @param {string} transactionId
 */
export function setHPRAuthCredentials(requestMethod, transactionId) {
  store.dispatch({
    type: abdmActionTypes.ADD_ABDM_AUTH_CREDENTIALS,
    payload: {
      requestMethod: requestMethod,
      transactionId: transactionId
    }
  });
}
/**
 * clear ABDM auth Credentials
 */
export function clearABDMAuthCredentials() {
  store.dispatch({
    type: abdmActionTypes.CLEAR_ABDM_AUTH_CREDENTIALS
  });
}
/**
 * reset HPR registration
 */
export function resetHPRRegistrationStep() {
  store.dispatch({
    type: abdmActionTypes.RESET_HPR_REGISTRATION_STEP
  });
}
/**
 * login with healthId
 * @param {string} hprId
 */
export function loginWithHealthId(hprId) {
  store.dispatch({
    type: abdmActionTypes.LOGIN_WITH_HPR_ID,
    payload: {
      hprId: hprId
    }
  });
}
/**
 * logout HPR
 */
export function logoutHPR() {
  store.dispatch({
    type: abdmActionTypes.LOGOUT_HPR_ACCOUNT
  });
}
