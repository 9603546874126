import { all, put, takeEvery } from "@redux-saga/core/effects";
import { otpRequest, otpValidate } from "../../services/authentication";
import store from "../store/store";
import { seed } from "../../utils/constants";
import { getVerificationCodeFromEmulator } from "../../services/api.mock";
import { setErrorStatus } from "../status/actions";
import { requestLogin, requestSignUp } from "../authentication/actions";
import { mockAuthVerifyOtp } from "./actions";

export const mockActionTypes = {
  MOCK_AUTH: "MOCK_AUTH",
  MOCK_AUTH_VERIFY_OTP: "MOCK_AUTH_VERIFY_OTP"
};

function* mockAuthWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    const phoneNumber =
      action.payload.userType === "owner"
        ? seed.ownerPhoneNumber
        : action.payload.userType === "manager"
        ? seed.managerPhoneNumber
        : action.payload.userType === "staff"
        ? seed.staffPhoneNumber
        : action.payload.userType === "doctor"
        ? seed.doctorPhoneNumber
        : seed.deleteAblePhoneNumber;

    const defaultOTP = "000000";
    const verificationId = yield otpRequest(phoneNumber);

    yield put({
      type: "SET_AUTH_VERIFICATION_ID",
      payload: {
        verificationId: verificationId
      }
    });
    if (process.env.REACT_APP_STAGING === "local") {
      mockAuthVerifyOtp();
    } else {
      yield put({
        type: "SET_AUTH_LOADING",
        payload: {
          loading: false
        }
      });
      yield put({
        type: "VERIFY_OTP",
        payload: {
          loginOtp: defaultOTP,
          userName: "chan",
          phoneNumber: phoneNumber,
          type: action.payload.type
        }
      });
    }
  } catch (error) {
    console.warn(error);
    yield setErrorStatus(error);
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* mockAuthVerifyOtpWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: true
      }
    });
    const otpResponse = yield getVerificationCodeFromEmulator();
    const verificationCode =
      otpResponse.verificationCodes[otpResponse.verificationCodes.length - 1];
    if (verificationCode) {
      const authCredential = yield otpValidate(
        verificationCode.code,
        store.getState().auth.credentials.verificationId
      );
      yield put({
        type: "SET_AUTH_CREDENTIALS",
        payload: {
          authCredential: authCredential
        }
      });

      if (action.payload.type === "signUp") {
        requestSignUp(action.payload.phoneNumber, action.payload.userName);
      } else {
        requestLogin();
      }
    }
    yield put({
      type: "SET_AUTH_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* mockWatcher() {
  yield all([
    takeEvery("MOCK_AUTH", mockAuthWorker),
    takeEvery("MOCK_AUTH_VERIFY_OTP", mockAuthVerifyOtpWorker)
  ]);
}
