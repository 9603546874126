import store from "../store/store";
import { profileActionTypes } from "./saga";

/**
 * @module ProfileSaga
 */

/**
 * set account profiles
 * @param {string} profiles
 * @param {boolean} firstUpdate
 */
export function setAccountProfiles(profiles, firstUpdate) {
  store.dispatch({
    type: profileActionTypes.SET_ACCOUNT_PROFILES,
    payload: {
      profiles: profiles,
      firstUpdate: firstUpdate
    }
  });
}
/**
 * set profile data
 * @param {object} data
 */
export function setProfileData(data) {
  store.dispatch({
    type: profileActionTypes.SET_PROFILE_DATA,
    payload: {
      data: data
    }
  });
}

/**
 * remove account profiles
 */
export function removeAccountProfiles() {
  store.dispatch({
    type: profileActionTypes.REMOVE_ACCOUNT_PROFILES
  });
}
/**
 * create profile
 */
export function createProfileRequest() {
  store.dispatch({
    type: profileActionTypes.CREATE_PROFILE
  });
}
/**
 * edit profile
 * @param {object} data
 * @param {object} currentField
 */
export function editProfileRequest(data, currentField) {
  store.dispatch({
    type: profileActionTypes.EDIT_PROFILE,
    payload: {
      currentField: currentField,
      data: data
    }
  });
}
/**
 * set profile picture
 * @param {string} profilePicture
 */
export function setProfilePicture(profilePicture) {
  store.dispatch({
    type: profileActionTypes.SET_PROFILE_PICTURE,
    payload: {
      profilePicture: profilePicture
    }
  });
}
