import React from "react";
import star from "../../../assets/icons/star_twinkel.svg";
import Button from "../../../front-end-global-components/components/Button/Button";

function ProfileCompleteSuccess(props) {
  return (
    <>
      <section className="inherit-parent-height inherit-parent-width flex-place-children-page-center padding-medium">
        <article className="inherit-parent-width max-width-500px flex-place-children-page-center">
          <img src={star} alt="completion_success" />
          <h3 className="font-weight-normal margin-default font-size-large font-family-gilroy-bold text-align-center">
            Great! You have completed your profile
          </h3>
          <p className="font-size-medium font-family-gilroy-medium text-align-center">
            Sit back and relax while our team verifies the information you have
            provided.
          </p>

          <Button
            boxShadow={false}
            className="margin-larger font-family-gilroy-medium"
            text="Okay"
            onClick={(_) => {
              props.navigate("/");
            }}
            disabled={false}
          />
        </article>
      </section>
    </>
  );
}

export default ProfileCompleteSuccess;
