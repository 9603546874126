const clinicsReducer = (
  state = {
    loading: false,
    inviteCode: null,
    requests: null,
    currentClinic: null,
    data: null
  },
  action
) => {
  switch (action.type) {
    case "SET_CLINICS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };
    case "SET_CLINICS":
      return {
        ...state,
        data: {
          ...action.payload.data
        }
      };
    case "SET_CLINICS_REQUEST":
      return {
        ...state,
        requests: [...action.payload.requests]
      };
    case "REMOVE_CLINICS":
      return {
        ...state,
        data: null
      };
    case "SET_INVITE_CODE":
      return {
        ...state,
        inviteCode: action.payload.data
      };
    case "CLEAR_INVITE_CODE":
      return {
        ...state,
        inviteCode: null
      };
    case "SET_CURRENT_CLINIC":
      return {
        ...state,
        currentClinic: action.payload.data
      };
    case "RESET":
      return {
        loading: false,
        inviteCode: null,
        requests: null,
        currentClinic: null,
        data: null
      };
    default:
      return state;
  }
};

export default clinicsReducer;
