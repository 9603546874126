import { all } from "redux-saga/effects";
import { statusWatcher } from "./status/saga";
import abdmWatcher from "./abdm/saga";
import abdmPatientsWatcher from "./abdmPatients/saga";
import appWatcher from "./app/saga";
import { authWatcher } from "./authentication/saga";
import { profileWatcher } from "./profile/saga";
import { downloadsWatcher } from "./downloads/saga";
import { clinicsWorkers } from "./clinics/saga";
import documentsWatcher from "./documents/saga";
import { notificationsWorkers } from "./notifications/saga";
import queriesWatcher from "./queries/saga";
import mockWatcher from "./mock/saga";

//root saga
export default function* rootSaga() {
  yield all([
    authWatcher(),
    profileWatcher(),
    statusWatcher(),
    mockWatcher(),
    clinicsWorkers(),
    documentsWatcher(),
    notificationsWorkers(),
    downloadsWatcher(),
    queriesWatcher(),
    appWatcher(),
    abdmWatcher(),
    abdmPatientsWatcher()
  ]);
}
