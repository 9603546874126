import store from "../store/store";
import { authActionTypes } from "./saga";
/**
 * @module AuthSaga
 */

/**
 * Sends OTP to the Phone number in payload
 * Type indicates the authentication type login or sign in
 * @param {string} phoneNumber
 * @param {string} type
 */
export function sendOtp(phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.SEND_OTP,
    payload: {
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * Verifies OTP from the Phone number in payload
 * clinicName indicates the name from payload
 * Type indicates the authentication type login or sign in
 * @param {string} loginOtp
 * @param {string} clinicName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function verifyOtp(loginOtp, userName, phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      userName: userName,
      phoneNumber: phoneNumber,
      type: type
    }
  });
}
/**
 * Sign in using userName and phoneNumber from payload
 * @param {string} userName
 * @param {string} phoneNumber
 */
export function requestSignUp(userName, phoneNumber) {
  store.dispatch({
    type: authActionTypes.SIGNUP,
    payload: {
      userName: userName,
      phoneNumber: phoneNumber
    }
  });
}
/**
 * Login in using OTP from payload
 * @param {string} loginOtp
 */
export function requestLogin(loginOtp) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: {
      loginOtp: loginOtp
    }
  });
}
/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT
  });
}
/**
 * Deletes the account
 */
export function deleteAccount() {
  store.dispatch({
    type: authActionTypes.DELETE_ACCOUNT
  });
}

/**
 * Clears the credentials after login
 */
export function clearCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_CREDENTIALS
  });
}
/**
 * Update Access token
 * @param {string} accessToken
 */
export function addAccessToken(accessToken, phoneNumber, uid) {
  store.dispatch({
    type: authActionTypes.ADD_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken,
      phoneNumber: phoneNumber,
      uid: uid
    }
  });
}
