export async function getVerificationCodeFromEmulator() {
  const response = await fetch(
    `http://localhost:9099/emulator/v1/projects/karthealth-dev/verificationCodes`,
    {
      method: "get"
    }
  );
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
