import store from "../store/store";
import { clinicsActionTypes } from "./saga";
/**
 * @module ClinicSaga
 */

/**
 * connection accept
 * @param {string} inviteCode
 */
export function acceptConnectionRequest(inviteCode) {
  store.dispatch({
    type: clinicsActionTypes.ACCEPT_CONNECTION_REQUEST,
    payload: {
      inviteCode: inviteCode
    }
  });
}
/**
 * set current clinic
 * @param {string} clinicID
 */
export function setCurrentClinic(clinicID) {
  store.dispatch({
    type: clinicsActionTypes.SELECT_CURRENT_CLINIC,
    payload: {
      data: clinicID
    }
  });
}

/**
 * set connected clinic
 * @param {object} data
 */
export function putConnectedClinics(data) {
  store.dispatch({
    type: clinicsActionTypes.PUT_CONNECTED_CLINICS,
    payload: {
      data: data
    }
  });
}
/**
 * remove connected clinic
 * @param {string} clinicId
 */
export function removeConnectedClinic(clinicId) {
  store.dispatch({
    type: clinicsActionTypes.REMOVE_CONNECTED_CLINIC,
    payload: {
      clinicId: clinicId
    }
  });
}
/**
 * remove clinic request
 * @param {string} requestDocumentId
 */
export function removeClinicRequest(requestDocumentId) {
  store.dispatch({
    type: clinicsActionTypes.REMOVE_CLINICS_REQUEST,
    payload: {
      requestDocumentId: requestDocumentId
    }
  });
}
