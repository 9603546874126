import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import abdmReducer from "./abdm/reducer";
import abdmPatientsReducer from "./abdmPatients/reducer";
import appReducer from "./app/reducer";
import authReducer from "./authentication/reducer";
import profileReducer from "./profile/reducer";
import downloadsReducer from "./downloads/reducer";
import clinicsReducer from "./clinics/reducer";
import documentsReducer from "./documents/reducer";
import notificationsReducer from "./notifications/reducer";
import queriesReducer from "./queries/reducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"]
};

const abdmPersistConfig = {
  key: "abdm",
  storage: storage,
  blacklist: ["loading", "registrationStep", "isMobileVerified"]
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"]
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["loading"]
};

const clinicsPersistConfig = {
  key: "clinics",
  storage: storage,
  blacklist: ["loading"]
};

const documentsPersistConfig = {
  key: "documents",
  storage: storage,
  blacklist: ["loading"]
};

const notificationsPersistConfig = {
  key: "notifications",
  storage: storage,
  blacklist: ["loading", "data", "paginationLoading", "pagination"]
};

const downloadsPersistConfig = {
  key: "downloads",
  storage: storage,
  blacklist: ["loading"]
};

const queriesPersistConfig = {
  key: "queries",
  storage: storage,
  blacklist: ["loading"]
};

const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: []
};

const abdmPatientsPersistConfig = {
  key: "abdmPatients",
  storage: storage,
  blacklist: ["loading", "searchKey", "selectedPatient", "searchResults"]
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "auth",
    "abdm",
    "profile",
    "status",
    "clinics",
    "documents",
    "notifications",
    "downloads",
    "queries",
    "app",
    "patients"
  ]
};

//root reducer
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  abdm: persistReducer(abdmPersistConfig, abdmReducer),
  abdmPatients: persistReducer(abdmPatientsPersistConfig, abdmPatientsReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  clinics: persistReducer(clinicsPersistConfig, clinicsReducer),
  documents: persistReducer(documentsPersistConfig, documentsReducer),
  notifications: persistReducer(
    notificationsPersistConfig,
    notificationsReducer
  ),
  downloads: persistReducer(downloadsPersistConfig, downloadsReducer),
  queries: persistReducer(queriesPersistConfig, queriesReducer),
  app: persistReducer(appPersistConfig, appReducer)
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
