const abdmReducer = (
  state = {
    loading: false,
    auth: {
      data: {
        accessToken: null,
        sessionId: null
      },
      otp: {
        requestMethod: null,
        transactionId: null
      }
    },
    data: {
      hprId: null,
      aadhaarDetails: null
    },
    isMobileVerified: null,
    registrationStep: "verifyAadhaar"
  },
  action
) => {
  switch (action.type) {
    case "SET_ABDM_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_HPR_ID":
      return {
        ...state,
        data: {
          ...state.data,
          hprId: action.payload.hprId
        }
      };

    case "SET_IS_MOBILE_VERIFIED":
      return {
        ...state,
        isMobileVerified: action.payload.isMobileVerified
      };

    case "SET_HPR_REGISTRATION_STEP":
      return {
        ...state,
        registrationStep: action.payload.registrationStep
      };

    case "SET_ABDM_ACCESS_TOKEN":
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            accessToken: action.payload.abdmAccessToken
          }
        }
      };

    case "SET_HPR_SESSION_ID":
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {
            ...state.auth.data,
            sessionId: action.payload.sessionId
          }
        }
      };

    case "SET_AADHAAR_DETAILS":
      return {
        ...state,
        data: {
          ...state.data,
          aadhaarDetails: action.payload.aadhaarDetails
        }
      };

    case "SET_ABDM_OTP_CREDENTIALS":
      return {
        ...state,
        auth: {
          ...state.auth,
          otp: {
            ...state.auth.otp,
            requestMethod: action.payload.requestMethod,
            transactionId: action.payload.transactionId
          }
        }
      };

    case "LOGOUT":
    case "LOGOUT_HPR":
    case "RESET":
      return {
        loading: false,
        auth: {
          data: {
            accessToken: null,
            sessionId: null
          },
          otp: {
            requestMethod: null,
            transactionId: null
          }
        },
        data: {
          hprId: null,
          aadhaarDetails: null
        },
        isMobileVerified: null,
        registrationStep: "verifyAadhaar"
      };

    default:
      return state;
  }
};
export default abdmReducer;
