import React from "react";
import ReactDOM from "react-dom";
import "./front-end-global-components/design-system.css";
import "./index.css";
import App from "./App";
import LogRocket from "logrocket";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./redux/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./front-end-global-components/components/ErrorBoundary/ErrorBoundary";

let startApp = () => {
  ReactDOM.render(
    <ErrorBoundary
      userType="doctor"
      onClick={() => {
        localStorage.clear();
        window.location.reload();
      }}
      handleComponentDidCatch={(error, errorInfo) => {
        // window.gtag("event", "exception", {
        //   code: error.code,
        //   userType: this.props.userType,
        //   message: error.message,
        //   fatal: true
        // });
        LogRocket.captureMessage(error.message, {
          tags: {
            userType: "doctor"
          },
          extra: {
            fatal: true,
            code: error.code
          }
        });
      }}
    >
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

if (window.Cypress) {
  window.store = store;
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onUpdate: onSWUpdate,
    onSuccess: (_controller, buildNo) => {
      console.log(`Successfully updated and serving build ${buildNo}`);
    }
  });
}

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

const skipWaitingClearCacheAndReload = async () => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data === "FORCE_UPDATE_COMPLETE") {
      window.location.reload(true);
    }
  });

  navigator.serviceWorker.ready.then((registration) => {
    registration.waiting.postMessage("FORCE_UPDATE");
  });
};

async function onSWUpdate(controller, force) {
  if (force === true && controller) {
    const criticalUpdateCard = document.getElementById("critical-update-card");
    criticalUpdateCard.classList.remove("display-none");
    criticalUpdateCard.classList.add("display-block");
    const criticalUpdateButton = document.getElementById(
      "critical-update-confirm-button"
    );
    criticalUpdateButton.addEventListener("click", () =>
      skipWaitingClearCacheAndReload()
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// var isIOS = navigator.userAgent.match(/(ipod|ipad|iphone)/i) ? true : false;
//  CORDOVA REFS
//  https://stackoverflow.com/questions/46638310/cordova-incorrectly-resolving-paths-of-image-elements-constructed-during-javascr
//  https://www.softwire.com/insights/submitting-your-cordova-app-to-the-apple-app-store/
//  https://fjolt.com/article/react-apache-cordova-ios-android
//  https://www.freecodecamp.org/news/cordova-ios-application-development-setup-to-deployment/
//  https://www.npmjs.com/package/cordova/v/9.0.0
//  https://stackoverflow.com/questions/17980759/xcode-select-active-developer-directory-error
//  https://github.com/smpnjn/react-cordova-native/tree/main/cordova
//  https://github.com/pshubham95/cordova-react-example/blob/master/package.json
//  https://stackoverflow.com/questions/55939354/tool-xcodebuild-requires-xcode-but-active-developer-directory-library-devel
//  https://github.com/nodejs/node-gyp/issues/569
//  https://medium.com/@pshubham/using-react-with-cordova-f235de698cc3
//  https://stackoverflow.com/questions/67863355/xcode-12-4-unsupported-os-version-after-iphone-ios-update-14-7
