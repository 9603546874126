import { all, put, takeEvery } from "@redux-saga/core/effects";
import { createQuery } from "../../services/database";
import { throwError } from "../../services/error";
import { validProfileObject } from "../../services/utils";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";

export const queriesActionTypes = {
  CREATE_QUERY: "CREATE_QUERY"
};

function* createQueryWorker(action) {
  try {
    yield put({
      type: "SET_QUERY_LOADING",
      payload: {
        loading: true
      }
    });

    if (validProfileObject(store.getState().profile.data)) {
      const profile = Object.values(store.getState().profile.data)[0];
      const data = action.payload.queryData;
      data["fullName"] = profile.public.fullName;
      data["phoneNumber"] = profile.public.phoneNumber;
      data["profileId"] = profile.public.uid;
      data["timestamp"] = Math.floor(Date.now() / 1000);
      data["userType"] = "doctor";
      data["description"] = action.payload.queryData.description;
      data["topic"] = action.payload.queryData.topic;
      yield createQuery({
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        profileId: data.profileId,
        timestamp: data.timestamp,
        userType: data.userType,
        description: data.description,
        topic: data.topic
      });
      action.payload.navigate("/querysuccess");
    } else {
      throwError("custom", "Something went wrong");
    }

    yield put({
      type: "SET_QUERY_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_QUERY_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

export default function* queriesWatcher() {
  yield all([takeEvery("CREATE_QUERY", createQueryWorker)]);
}
