import store from "../store/store";
import { downloadsActionTypes } from "./saga";

/**
 * @module DownloadsSaga
 */

/**
 * get document download URL
 * @param {string} documentType
 * @param {string} documentId
 */
export function getDocumentDownloadUrl(documentType, documentId) {
  store.dispatch({
    type: downloadsActionTypes.GET_DOCUMENT_DOWNLOAD_URL,
    payload: {
      documentType: documentType,
      documentId: documentId
    }
  });
}
/**
 * get user image download URL
 * @param {object} isProfilePictureUpdatedJustNow
 */
export function getUserImageDownloadUrl(isProfilePictureUpdatedJustNow) {
  store.dispatch({
    type: downloadsActionTypes.GET_USER_IMAGE_DOWNLOAD_URL,
    payload: {
      isProfilePictureUpdatedJustNow: isProfilePictureUpdatedJustNow
    }
  });
}
/**get connected clinics images download URL
 *
 * @param {string} clinicsUid
 */
export function getConnectedClinicsImageDownloadUrl(clinicsUid) {
  store.dispatch({
    type: downloadsActionTypes.GET_CONNECTED_CLINICS_IMAGE_DOWNLOAD_URL,
    payload: {
      clinicsUid: clinicsUid
    }
  });
}
/**
 * get search image download URL
 */
export function getSearchedImageDownloadUrl() {
  store.dispatch({
    type: downloadsActionTypes.GET_SEARCHED_PATIENTS_IMAGE_DOWNLOAD_URL
  });
}
/**
 * get uploader image download URL
 * @param {string} uploaderType
 * @param {string} uploaderId
 */
export function getUploaderImageDownloadUrl(uploaderType, uploaderId) {
  store.dispatch({
    type: downloadsActionTypes.GET_UPLOADER_IMAGE_DOWNLOAD_URL,
    payload: {
      uploaderType: uploaderType,
      uploaderId: uploaderId
    }
  });
}
/**
 * get prescription template download URL
 */
export function getPrescriptionTemplateDownloadUrl() {
  store.dispatch({
    type: downloadsActionTypes.GET_PRESCRIPTION_TEMPLATE_DOWNLOAD_URL
  });
}
/**
 * get patients image download URL
 * @param {object} patientsData
 */
export function getPatientsImageDownloadUrl(patientsData) {
  store.dispatch({
    type: downloadsActionTypes.GET_PATIENTS_IMAGE_DOWNLOAD_URL,
    payload: {
      patientsData: patientsData
    }
  });
}
