import { getApp } from "firebase/app";
import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  connectStorageEmulator
} from "firebase/storage";
import { setErrorStatus } from "../redux/status/actions";
import store from "../redux/store/store";
import { bucketNames } from "./utils";

const switchBucket = (bucketName) => {
  if (process.env.REACT_APP_STAGING === "local") {
    connectStorageEmulator(getStorage(getApp(), bucketName), "localhost", 9199);
  }
  return getStorage(getApp(), bucketName);
};

export async function uploadIdentityDocument(uid, fileName, file) {
  const customStorage = switchBucket(
    `gs://${bucketNames.providerIdentityDocuments}`
  );
  const result = await uploadBytes(
    ref(customStorage, `/${uid}/${fileName}`),
    file
  );
  return result;
}

export async function uploadProfilePicture(file) {
  const customStorage = switchBucket(
    `gs://${bucketNames.nintoProfilePictures}`
  );
  const result = await uploadBytes(
    ref(customStorage, `doctors/${file.name}`),
    file
  );
  return result;
}

export async function getFileUrl(storageLocation) {
  // reference
  // "gs://karthealth-dev-patient-3wj52g8h4nh9p/test.pdf"
  const slashElementIndex = storageLocation.indexOf("/", 12);
  const fileName = storageLocation.substring(
    slashElementIndex + 1,
    storageLocation.length
  );
  const bucketName = storageLocation.substring(0, slashElementIndex);
  const customStorage = switchBucket(bucketName);
  const url = await getDownloadURL(ref(customStorage, fileName));
  return url;
}

export async function getPrescriptionTemplate() {
  const [doctorId] = Object.keys(store.getState().profile.data);
  const currentClinicId = store.getState().clinics.currentClinic;

  try {
    // const customStorage = switchBucket(`gs://prescription-templates`);
    const customStorage = switchBucket(
      `gs://${bucketNames.prescriptionTemplates}`
    );
    const url = await getDownloadURL(
      ref(customStorage, `${doctorId}/${currentClinicId}/default.pdf`)
    );
    return url;
  } catch (error) {
    setErrorStatus(error);
  }
}

export async function uploadPrescription(
  currentClinicId,
  patientId,
  file,
  metaData
) {
  const customStorage = switchBucket(`gs://${bucketNames.abdmDocumentsUpload}`);
  await uploadBytes(
    ref(
      customStorage,
      `documents/${currentClinicId}/${patientId}/Prescription/${file.name}`
    ),
    file,
    {
      customMetadata: {
        ...metaData
      }
    }
  );
}

export async function uploadPdfNotes(fullUrl, file, metaData) {
  const storageUrlAsList = fullUrl.split("/");
  const baseUrl = `${storageUrlAsList[3]}/${storageUrlAsList[4]}/${storageUrlAsList[5]}/notes/${storageUrlAsList[7]}`;
  // const bucketName = `${storageUrlAsList[0]}//${storageUrlAsList[2]}`;
  const customStorage = switchBucket(`gs://${bucketNames.documentsUpload}`);
  const result = await uploadBytes(
    ref(customStorage, `${baseUrl}/${file.name}`),
    file,
    {
      customMetadata: {
        ...metaData
      }
    }
  );
  return result;
}
