import React, { useState, useEffect } from "react";

import Button from "../../../front-end-global-components/components/Button/Button";
import InputBox from "../../../front-end-global-components/components/InputBox/InputBox";
import NativeSelect from "../../../front-end-global-components/components/NativeSelect/NativeSelect";
import Select from "../../../front-end-global-components/components/Select/Select";
import {
  isValidArray,
  isValidObject,
  isValidString
} from "../../../front-end-global-components/services/validators";
import { validProfileObject } from "../../../services/utils";
import {
  dateOfBirthValidation,
  yearOfExperienceValidation
} from "../../../utils/constants";

export default function CompleteProfile(props) {
  const [formObj, setFormObj] = useState({
    email: null,
    gender: "Male",
    city: null,
    speciality: [],
    yearOfExperience: null,
    dateOfBirth: null
  });

  const [isValidFormObj, setIsValidFormObj] = useState({
    email: true,
    gender: true,
    city: true,
    speciality: true,
    yearOfExperience: true,
    dateOfBirth: true
  });

  useEffect(() => {
    if (
      validProfileObject(props.profile.data) &&
      props.profile.loading === false
    ) {
      const [docId] = Object.keys(props.profile.data);
      const userObj = props.profile.data[docId];
      if (isValidObject(userObj.public)) {
        const newObj = {
          email: userObj.email ? userObj.email : null,
          gender: userObj.public.gender
            ? getConvertedGender(userObj.public.gender, true)
            : "",
          city: userObj.public.city ? userObj.public.city : null,
          speciality: isValidArray(userObj.public.speciality)
            ? userObj.public.speciality
            : [],
          dateOfBirth: userObj.dateOfBirth
            ? new Date(userObj.dateOfBirth).toISOString().split("T")[0]
            : null,
          yearOfExperience:
            isValidObject(userObj.public.experience) &&
            typeof userObj.public.experience.yearOfExperience === "number"
              ? userObj.public.experience.yearOfExperience
              : null
        };

        if (window.document.activeElement) {
          delete newObj[window.document.activeElement.getAttribute("name")];
        }
        setFormObj(newObj);
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.profile.data)]);

  const getConvertedGender = (gender, toCamelCase) => {
    if (gender === "Male" || gender === "male") {
      return toCamelCase ? "Male" : "male";
    } else if (gender === "Female" || gender === "female") {
      return toCamelCase ? "Female" : "female";
    } else if (gender === "Others" || gender === "others") {
      return toCamelCase ? "Others" : "others";
    } else {
      return "";
    }
  };

  const onFormInputChange = (event) => {
    if (event.target.name === "speciality") return;
    if (event.target.name === "gender") {
      setFormObj({
        ...formObj,
        [event.target.name]: getConvertedGender(event.target.value)
      });
    } else if (event.target.name === "yearOfExperience") {
      setFormObj({
        ...formObj,
        [event.target.name]: event.target.value
          ? parseInt(event.target.value)
          : null
      });
    } else {
      setFormObj({ ...formObj, [event.target.name]: event.target.value });
    }
  };

  const onSpecialtyAdded = (listValue) => {
    if (!isValidArray(listValue)) return;

    if (!validProfileObject(props.profile.data)) return;
    const [docId] = Object.keys(props.profile.data);
    const profileData = props.profile.data[docId];

    if (isValidArray(profileData.public.speciality)) {
      const isSame =
        profileData.public.speciality.length === listValue.length &&
        profileData.public.speciality.every((element, index) => {
          return element === listValue[index];
        });
      if (!isSame) {
        setFormObj({
          ...formObj,
          speciality: [...listValue]
        });
      }
    } else {
      setFormObj({
        ...formObj,
        speciality: [...listValue]
      });
    }
  };

  const isSubmitButtonDisabled = () => {
    if (
      yearOfExperienceValidation(formObj.yearOfExperience).status === true &&
      isValidFormObj.email &&
      isValidFormObj.yearOfExperience &&
      isValidFormObj.dateOfBirth &&
      isValidString(formObj.email) &&
      isValidString(formObj.gender) &&
      isValidString(formObj.city) &&
      isValidArray(formObj.speciality) &&
      typeof formObj.yearOfExperience === "number" &&
      isValidString(formObj.dateOfBirth)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    const date = +new Date(formObj.dateOfBirth);
    let _value = formObj.email.trimStart();
    _value = _value.trimEnd();
    const newFormObject = {
      ...formObj,
      email: _value,
      experience: {
        yearOfExperience: parseInt(formObj.yearOfExperience)
      },
      dateOfBirth: date
    };
    delete newFormObject["yearOfExperience"];
    props.editProfileRequest({
      ...newFormObject
    });
  };

  return (
    <article className="remaining-body-height overflow-x-scroll flex-start-place-children-vertically ">
      <h3 className="font-weight-normal font-size-large padding-top-larger font-color-secondary font-family-gilroy-regular ">
        COMPLETE PROFILE
      </h3>
      {props.profile.loading === true ? (
        <RegistrationSuspense />
      ) : (
        <form
          data-cy="registration-details-form"
          className="inherit-parent-width inherit-parent-height grey-abstract-background-image max-width-500px display-flex flex-direction-column padding-medium"
          onChange={(event) => {
            onFormInputChange(event);
          }}
        >
          <main className="inherit-parent-width inherit-parent-height inherit-parent-height flex-basis-1 ">
            <InputBox
              className="inherit-parent-width margin-vertical-large"
              type="text"
              name="email"
              label="Email"
              labelClassName="letter-spacing-4-percentage"
              value={formObj.email}
              loading={
                props.profile.loading === true &&
                props.profile.currentField === "email"
              }
              isValidInput={(value) => {
                setIsValidFormObj({ ...isValidFormObj, email: value });
              }}
            />

            <section className="inherit-parent-width flex-direction-row flex-justify-content-space-between margin-vertical-large">
              <InputBox
                size="half"
                className=" border-radius-default margin-right-default"
                labelClassName="letter-spacing-4-percentage"
                type="date"
                name="dateOfBirth"
                label="Date Of Birth"
                value={formObj.dateOfBirth}
                loading={
                  props.profile.loading === true &&
                  props.profile.currentField === "dateOfBirth"
                }
                isValidInput={(value) => {
                  setIsValidFormObj({ ...isValidFormObj, dateOfBirth: value });
                }}
                validation={dateOfBirthValidation}
              />
              <NativeSelect
                size="half"
                className="margin-left-default"
                name="gender"
                label="Gender"
                labelClassName="letter-spacing-4-percentage"
                defaultValue={formObj.gender}
                options={["", "Male", "Female", "Others"]}
              />
            </section>

            <section className="inherit-parent-width flex-direction-row flex-justify-content-space-between margin-vertical-large">
              <InputBox
                size="half"
                className=" border-radius-default margin-right-default"
                type="text"
                name="city"
                label="City"
                labelClassName="letter-spacing-4-percentage"
                value={formObj.city}
                loading={
                  props.profile.loading === true &&
                  props.profile.currentField === "city"
                }
              />
              <InputBox
                size="half"
                className=" border-radius-default margin-left-default"
                name="yearOfExperience"
                label="Years of Experience"
                labelClassName="letter-spacing-4-percentage"
                type="number"
                autoComplete="false"
                required
                value={formObj.yearOfExperience}
                loading={
                  props.profile.loading === true &&
                  props.profile.currentField === "yearOfExperience"
                }
                isValidInput={(value) => {
                  setIsValidFormObj({
                    ...isValidFormObj,
                    yearOfExperience: value
                  });
                }}
                validation={yearOfExperienceValidation}
              />
            </section>

            <Select
              chips={formObj.speciality}
              modalTitle={"Choose your Specialties"}
              multiSelect={true}
              name="speciality"
              label="specialty"
              labelClassName="letter-spacing-4-percentage"
              onChipAdded={onSpecialtyAdded}
              loading={
                props.profile.loading === true &&
                props.profile.currentField === "speciality"
              }
              data-cy="speciality-select-box-comp"
              options={[
                "Allergy and immunology",
                "Orthopedics",
                "Anesthesiology",
                "Dermatology",
                "Diagnostic radiology",
                "Family medicine",
                "Internal medicine",
                "Medical genetics",
                "Neurology",
                "Nuclear medicine",
                "Obstetrics and gynecology",
                "Ophthalmology",
                "Pathology",
                "Pediatrics",
                "Physical medicine and rehabilitation",
                "Preventive medicine",
                "Psychiatry",
                "Radiation oncology",
                "Surgery",
                "Urology"
              ]}
            />
          </main>

          <footer className="margin-top-larger inherit-parent-width">
            <Button
              loading={props.profile.loading}
              id="submit-btn"
              type="submit"
              text="Continue"
              onClick={onSubmitData}
              disabled={isSubmitButtonDisabled()}
              boxShadow={false}
            />
            <button
              type="button"
              className=" inherit-parent-width margin-top-larger background-color-transparent font-size-small font-color-secondary font-family-gilroy-regular "
              onClick={() => {
                props.navigate("/");
              }}
            >
              Skip
            </button>
          </footer>
        </form>
      )}
    </article>
  );
}

function RegistrationSuspense() {
  return (
    <article className="screen-loader-wrapper flex-direction-column flex-justify-content-space-between padding-bottom-larger ">
      <main className="padding-medium max-width-588px inherit-parent-width ">
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-bottom-larger" />
        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>

        <section className="margin-vertical-larger display-flex-direction-row flex-justify-content-space-between">
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-right-default">
            <div className="padding-default" />
          </div>
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-left-default">
            <div className="padding-default" />
          </div>
        </section>
        <section className="margin-vertical-larger display-flex-direction-row flex-justify-content-space-between">
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-right-default">
            <div className="padding-default" />
          </div>
          <div className="shimmer inherit-parent-width padding-medium border-radius-default margin-left-default">
            <div className="padding-default" />
          </div>
        </section>

        <div className="shimmer padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
      </main>
      <div className=" margin-vertical-larger padding-medium max-width-588px inherit-parent-width">
        <div className="shimmer margin-top-larger padding-medium max-width-588px inherit-parent-width border-radius-default margin-vertical-larger">
          <div className="padding-default" />
        </div>
      </div>
    </article>
  );
}
