import store from "../store/store";
import mockActionTypes from "./saga";
/**
 * mock authentication
 * @param {string} userType
 * @param {string} type
 */
export function mockAuth(userType, type) {
  store.dispatch({
    type: mockActionTypes.MOCK_AUTH,
    payload: {
      type: type,
      userType: userType
    }
  });
}
/**
 * mock auth verify OTP
 * @param {string} loginOtp
 * @param {string} userName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function mockAuthVerifyOtp(loginOtp, userName, phoneNumber, type) {
  store.dispatch({
    type: mockActionTypes.MOCK_AUTH_VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      userName: userName,
      phoneNumber: phoneNumber,
      type: type
    }
  });
}
