import { isPureNumber } from "../front-end-global-components/services/validators";
import { Regex } from "../utils/regex";

export const isValidObject = (object) => {
  if (Array.isArray(object)) return false;
  return (
    object !== null &&
    object !== undefined &&
    typeof object === "object" &&
    Object.keys(object).length > 0
  );
};

export const isValidArray = (array) => {
  return Array.isArray(array) && array.length > 0;
};

export const isValidString = (text) => {
  return typeof text === "string" && text.trim().length > 0;
};

export const validation = (key, value, confirmPassword) => {
  switch (key) {
    case "phoneNumber":
      if (!value)
        return {
          status: true
        };
      if (Regex.phoneNumber.test(value) === false) {
        return {
          status: false,
          message: "Please enter a valid 10digit phone number"
        };
      } else {
        return {
          status: true
        };
      }

    case "aadhaarNumber":
      if (
        typeof value === "string" &&
        Regex.aadhaarNumber.test(value) === true
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid aadhaar number"
      };

    case "date": {
      if (
        !!value &&
        new Date().getFullYear() - new Date(value).getFullYear() <= 100
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid date"
      };
    }
    case "dateOfBirth":
    case "date of birth": {
      if (
        !!value &&
        new Date().getFullYear() - new Date(value).getFullYear() <= 100
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid date"
      };
    }
    case "time": {
      if (!!value) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid time"
      };
    }

    case "pinCode": {
      if (isPureNumber(value) && /^[1-9][0-9]{5}$/gm.test(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid pincode"
      };
    }
    case "float": {
      if (isPureNumber(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid value"
      };
    }

    case "email": {
      if (Regex.email.test(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid email address"
      };
    }

    case "password": {
      if (Regex.hprPassword.test(value)) {
        return {
          status: true
        };
      } else {
        return {
          status: false,
          message:
            "Must contain an uppercase, a lowercase, a number, a special character and at least 8 or more characters"
        };
      }
    }

    case "confirmPassword": {
      if (value === confirmPassword) {
        return {
          status: true
        };
      } else {
        return {
          status: false,
          message: "Password does not match"
        };
      }
    }

    default:
      if (!!value) {
        return {
          status: true
        };
      } else {
        return {
          status: false
        };
      }
  }
};
