import store from "../store/store";
import { documentsActionTypes } from "./saga";

/**
 * @module DocumentsSaga
 */

/**
 * get document data
 * @param {string} patientId
 */
export function getDocumentsData(patientId) {
  store.dispatch({
    type: documentsActionTypes.GET_DOCUMENTS_DATA,
    payload: {
      patientId: patientId
    }
  });
}

/**
 * get updated document
 */
export function getUpdatedDocuments() {
  store.dispatch({
    type: documentsActionTypes.GET_UPDATED_DOCUMENTS
  });
}

/**
 * set selected current document
 * @param {string} documentData
 */
export function selectCurrentDocument(documentData) {
  store.dispatch({
    type: documentsActionTypes.SET_SELECTED_DOCUMENT,
    payload: {
      currentDocument: documentData
    }
  });
}

/**
 * add document filter
 * @param {string} type
 * @param {string} filterValue
 */
export function addDocumentFilter(type, filterValue) {
  store.dispatch({
    type: documentsActionTypes.ADD_DOCUMENT_FILTER,
    payload: {
      type: type,
      filterValue: filterValue
    }
  });
}

/**
 * remove document filter
 * @param {string} type
 */
export function removeDocumentFilter(type) {
  store.dispatch({
    type: documentsActionTypes.REMOVE_DOCUMENT_FILTER,
    payload: {
      clear: type
    }
  });
}

/**
 * get dataTransfer Details
 * @param {string} patientId
 */
export function getDataTransferDetails(patientId) {
  store.dispatch({
    type: documentsActionTypes.GET_DATA_TRANSFER_DETAILS,
    payload: {
      patientId: patientId
    }
  });
}

/**
 * retry dataTransfer
 * @param {object} data
 */
export function retryDataTransfer(data) {
  store.dispatch({
    type: documentsActionTypes.RETRY_DATA_TRANSFER,
    payload: data
  });
}
