import store from "../store/store";
import { all, put, takeEvery } from "@redux-saga/core/effects";
import { getFileUrl } from "../../services/storage";
import {
  isValidArray,
  isValidObject,
  isValidString
} from "../../services/validators";
import { bucketNames, validValue } from "../../services/utils";
import { getPatientDocuments } from "../../services/database";
import { setErrorStatus } from "../status/actions";

export const downloadsActionTypes = {
  GET_DOCUMENT_DOWNLOAD_URL: "GET_DOCUMENT_DOWNLOAD_URL",
  GET_USER_IMAGE_DOWNLOAD_URL: "GET_USER_IMAGE_DOWNLOAD_URL",
  GET_PINNED_PATIENTS_IMAGE_DOWNLOAD_URL:
    "GET_PINNED_PATIENTS_IMAGE_DOWNLOAD_URL",
  GET_SEARCHED_PATIENTS_IMAGE_DOWNLOAD_URL:
    "GET_SEARCHED_PATIENTS_IMAGE_DOWNLOAD_URL",
  GET_CONNECTED_CLINICS_IMAGE_DOWNLOAD_URL:
    "GET_CONNECTED_CLINICS_IMAGE_DOWNLOAD_URL",
  GET_UPLOADER_IMAGE_DOWNLOAD_URL: "GET_UPLOADER_IMAGE_DOWNLOAD_URL",
  GET_PRESCRIPTION_TEMPLATE_DOWNLOAD_URL:
    "GET_PRESCRIPTION_TEMPLATE_DOWNLOAD_URL",
  GET_PATIENTS_IMAGE_DOWNLOAD_URL: "GET_PATIENTS_IMAGE_DOWNLOAD_URL"
};

function* getDocumentDownloadUrlWorker(action) {
  try {
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: true
      }
    });

    const documentId = action.payload.documentId;
    const patientId =
      store.getState().documents.currentDocument.patientId;
    const currentClinicId = store.getState().clinics.currentClinic;
    let documentData = store.getState().documents.currentDocument;
    if (documentData.processing === true) {
      yield new Promise((resolve, reject) => {
        let tryCount = 0;
        const interval = setInterval(async () => {
          if (tryCount === 5) {
            clearInterval(interval);
            reject({ code: "database", message: "Document under processing" });
          }
          tryCount++;
          const newDocument = await getPatientDocuments(
            patientId,
            currentClinicId
          );
          if (
            isValidObject(newDocument) &&
            isValidObject(newDocument[documentId])
          ) {
            if (newDocument[documentId].processing === false) {
              documentData = newDocument[documentId];
              clearInterval(interval);
              resolve();
            }
          } else {
            clearInterval(interval);
            reject({ code: "database", message: "Invalid document" });
          }
        }, 2000);
      });
    }

    const downloadsData = store.getState().downloads.data;

    if (typeof documentData.url === "string") {
      const newDocumentObj = {};
      let rootFileDownloadableUrl = null;

      if (
        isValidObject(documentData) &&
        isValidObject(downloadsData) &&
        isValidObject(downloadsData[action.payload.documentType]) &&
        isValidObject(downloadsData[action.payload.documentType][documentId]) &&
        typeof downloadsData[action.payload.documentType][documentId]
          .downloadUrl === "string"
      ) {
        rootFileDownloadableUrl =
          downloadsData[action.payload.documentType][documentId].downloadUrl;
      } else {
        yield new Promise((resolve, reject) => {
          let rootFileTryCount = 0;
          const rootFileInterval = setInterval(async () => {
            if (rootFileTryCount === 3) {
              clearInterval(rootFileInterval);
              reject({
                code: "database",
                message: "Document under processing"
              });
            }
            try {
              rootFileTryCount++;
              rootFileDownloadableUrl = await getFileUrl(documentData.url);
            } catch (error) {
              if (error.code !== "storage/unauthorized") {
                clearInterval(rootFileInterval);
                reject({
                  code: "database",
                  message: "Document under processing"
                });
              }
              rootFileTryCount++;
            }

            if (isValidString(rootFileDownloadableUrl)) {
              clearInterval(rootFileInterval);
              resolve();
            }
          }, 3000);
        });
      }

      let newNotesObj = {};
      if (isValidObject(documentData.notes)) {
        const doctorsId = Object.keys(documentData.notes);
        //Looping all doctors in Notes (with doctorsId) and accessing pages
        for (let i = 0; i < doctorsId.length; i++) {
          // let newNotesObj = {};
          const splittedString = documentData.url.split("/");
          const defaultPdfName = splittedString[splittedString.length - 1];
          const convertedUrlPath = documentData.url.replace(
            defaultPdfName,
            `${doctorsId[i]}.pdf`
          );

          yield new Promise((resolve, reject) => {
            let noteFileTryCount = 0;
            const noteFileInterval = setInterval(async () => {
              if (noteFileTryCount === 3) {
                if (
                  typeof downloadsData[action.payload.documentType][documentId]
                    .downloadUrl === "string" &&
                  isValidObject(
                    downloadsData[action.payload.documentType][documentId].notes
                  ) &&
                  typeof downloadsData[action.payload.documentType][documentId]
                    .notes[doctorsId[i]] === "string"
                ) {
                  newNotesObj[[doctorsId[i]]] =
                    downloadsData[action.payload.documentType][
                      documentId
                    ].notes[doctorsId[i]];
                  clearInterval(noteFileInterval);
                  resolve();
                } else {
                  clearInterval(noteFileInterval);
                  reject({
                    code: "database",
                    message: "Document under processing"
                  });
                }
              }
              noteFileTryCount++;
              try {
                newNotesObj[doctorsId[i]] = await getFileUrl(convertedUrlPath);
              } catch (error) {
                if (error.code !== "storage/unauthorized") {
                  clearInterval(noteFileInterval);
                  reject({
                    code: "database",
                    message: "Document under processing"
                  });
                } else if (error.code === "storage/retry-limit-exceeded") {
                }
              }
              if (isValidString(newNotesObj[doctorsId[i]])) {
                clearInterval(noteFileInterval);
                resolve();
              }
            }, 3000);
          });
        }
      }
      newDocumentObj[documentId] = {
        downloadUrl: rootFileDownloadableUrl,
        notes: isValidObject(newNotesObj) ? newNotesObj : null
      };

      const previousDownloadsState = store.getState().downloads.data;

      if (isValidObject(previousDownloadsState)) {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...previousDownloadsState,
              [action.payload.documentType]: {
                ...(isValidObject(
                  previousDownloadsState[action.payload.documentType]
                )
                  ? previousDownloadsState[action.payload.documentType]
                  : {}),
                ...newDocumentObj
              }
            }
          }
        });
      } else {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              [action.payload.documentType]: {
                ...newDocumentObj
              }
            }
          }
        });
      }
    } else if (
      action.payload.documentType === "symptoms" &&
      isValidObject(documentData.url)
    ) {
      let audioFileDownloadableUrl;
      if (
        typeof documentData.url.audio === "string" &&
        documentData.url.audio.trim().length !== 0
      ) {
        audioFileDownloadableUrl = yield getFileUrl(documentData.url.audio);
      }

      let symptomImages = [];
      if (isValidArray(documentData.url.images)) {
        const imageUrls = documentData.url.images;
        //Looping all doctors in Notes (with doctorsId) and accessing pages
        for (const imageUrl in imageUrls) {
          const downloadUrl = yield getFileUrl(imageUrls[imageUrl]);
          //Creating notes Object
          symptomImages = [...symptomImages, downloadUrl];
        }
      }

      const previousDownloadsState = store.getState().downloads.data;
      if (isValidObject(previousDownloadsState)) {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...(isValidObject(previousDownloadsState)
                ? previousDownloadsState
                : {}),
              symptoms: {
                ...(isValidObject(previousDownloadsState.symptoms)
                  ? previousDownloadsState.symptoms
                  : {}),
                [documentId]: {
                  downloadUrl: audioFileDownloadableUrl,
                  images: [...symptomImages]
                }
              }
            }
          }
        });
      } else {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              symptoms: {
                ...previousDownloadsState.symptoms,
                [documentId]: {
                  downloadUrl: audioFileDownloadableUrl,
                  images: [...symptomImages]
                }
              }
            }
          }
        });
      }
    }

    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* getUserImageDownloadUrl(action) {
  yield put({
    type: "SET_DOWNLOADS_LOADING",
    payload: {
      loading: true
    }
  });
  try {
    if (isValidObject(store.getState().profile.data)) {
      const doctorId = Object.keys(store.getState().profile.data)[0];
      const profilePicture = validValue(
        () => store.getState().profile.data[doctorId].private.profilePicture
      );

      if (
        (typeof profilePicture === "string" &&
          profilePicture !== null &&
          profilePicture !== undefined) ||
        action.payload.isProfilePictureUpdatedJustNow === true
      ) {
        try {
          const profilePictureDownloadableURL = yield getFileUrl(
            `gs://${bucketNames.nintoProfilePictures}/doctors/${doctorId}.png`
          );
          const previousDownloadsState = store.getState().downloads.data;
          if (isValidObject(previousDownloadsState)) {
            yield put({
              type: "SET_DOWNLOADS",
              payload: {
                data: {
                  ...(isValidObject(previousDownloadsState)
                    ? previousDownloadsState
                    : {}),
                  profilePictures: {
                    ...(isValidObject(previousDownloadsState.profilePictures)
                      ? previousDownloadsState.profilePictures
                      : {}),
                    doctors: {
                      ...(isValidObject(
                        previousDownloadsState.profilePictures
                      ) &&
                      isValidObject(
                        previousDownloadsState.profilePictures.doctors
                      )
                        ? previousDownloadsState.profilePictures.doctors
                        : {}),
                      [doctorId]: profilePictureDownloadableURL
                    }
                  }
                }
              }
            });
          } else {
            yield put({
              type: "SET_DOWNLOADS",
              payload: {
                data: {
                  profilePictures: {
                    doctors: {
                      [doctorId]: profilePictureDownloadableURL
                    }
                  }
                }
              }
            });
          }
        } catch (_) {}
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* getPinnedPatientsImageDownloadUrlWorker(action) {
  yield put({
    type: "SET_DOWNLOADS_LOADING",
    payload: {
      loading: true
    }
  });
  try {
    // action.payload.patientsId
    if (isValidObject(action.payload.pinnedPatientsDataObj)) {
      const newPinnedPatientsObject = {};
      for (
        let i = 0;
        i < Object.keys(action.payload.pinnedPatientsDataObj).length;
        i++
      ) {
        const patientDataObj =
          action.payload.pinnedPatientsDataObj[
            Object.keys(action.payload.pinnedPatientsDataObj)[i]
          ];
        try {
          const downloadableUrl = yield getFileUrl(
            patientDataObj.private.profilePicture
          );
          newPinnedPatientsObject[patientDataObj.private.pid] = downloadableUrl;
        } catch (error) {
          newPinnedPatientsObject[patientDataObj.private.pid] = null;
        }
      }
      const previousDownloadsState = store.getState().downloads.data;

      if (isValidObject(previousDownloadsState)) {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...(isValidObject(previousDownloadsState)
                ? previousDownloadsState
                : {}),
              profilePictures: {
                ...(isValidObject(previousDownloadsState.profilePictures)
                  ? previousDownloadsState.profilePictures
                  : {}),
                patients: {
                  ...(isValidObject(previousDownloadsState.profilePictures) &&
                  isValidObject(previousDownloadsState.profilePictures.patients)
                    ? previousDownloadsState.profilePictures.patients
                    : {}),
                  ...newPinnedPatientsObject
                }
              }
            }
          }
        });
      } else {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              profilePictures: {
                patients: {
                  ...newPinnedPatientsObject
                }
              }
            }
          }
        });
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* getConnectedClinicsImageDownloadUrlWorker(action) {
  yield put({
    type: "SET_DOWNLOADS_LOADING",
    payload: {
      loading: true
    }
  });
  try {
    // action.payload.patientsId
    if (isValidArray(action.payload.clinicsUid)) {
      const newConnectedClinicsObject = {};
      for (let i = 0; i < action.payload.clinicsUid.length; i++) {
        try {
          const downloadableUrl = yield getFileUrl(
            `gs://${bucketNames.nintoProfilePictures}/clinics/${action.payload.clinicsUid[i]}.png`
          );
          newConnectedClinicsObject[action.payload.clinicsUid[i]] =
            downloadableUrl;
        } catch (error) {
          newConnectedClinicsObject[action.payload.clinicsUid[i]] = null;
        }
      }

      const previousDownloadsState = store.getState().downloads.data;

      if (isValidObject(previousDownloadsState)) {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...(isValidObject(previousDownloadsState)
                ? previousDownloadsState
                : {}),
              profilePictures: {
                ...(isValidObject(previousDownloadsState.profilePictures)
                  ? previousDownloadsState.profilePictures
                  : {}),
                clinics: {
                  ...(isValidObject(previousDownloadsState.profilePictures) &&
                  isValidObject(previousDownloadsState.profilePictures.clinics)
                    ? previousDownloadsState.profilePictures.clinics
                    : {}),
                  ...newConnectedClinicsObject
                }
              }
            }
          }
        });
      } else {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              profilePictures: {
                clinics: {
                  ...newConnectedClinicsObject
                }
              }
            }
          }
        });
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* getUploaderImageDownloadUrlWorker(action) {
  try {
    if (action.payload.uploaderType === "doctor") {
      const newDoctorsObject = {};
      try {
        const downloadableUrl = yield getFileUrl(
          `gs://${bucketNames.nintoProfilePictures}/doctors/${action.payload.uploaderId}.png`
        );
        newDoctorsObject[action.payload.uploaderId] = downloadableUrl;
      } catch (error) {
        newDoctorsObject[action.payload.uploaderId] = null;
      }

      const data = newValueWithPreviousState("doctors", newDoctorsObject);

      yield put({
        type: "SET_DOWNLOADS",
        payload: {
          data: {
            ...data
          }
        }
      });
    } else {
      const newClinicsObject = {};
      try {
        const downloadableUrl = yield getFileUrl(
          `gs://${bucketNames.nintoProfilePictures}/clinics/${action.payload.uploaderId}.png`
        );
        newClinicsObject[action.payload.uploaderId] = downloadableUrl;
      } catch (error) {
        newClinicsObject[action.payload.uploaderId] = null;
      }

      const data = newValueWithPreviousState("clinics", newClinicsObject);

      yield put({
        type: "SET_DOWNLOADS",
        payload: {
          data: {
            ...data
          }
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* getPrescriptionTemplateDownloadUrlWorker() {
  yield put({
    type: "SET_DOWNLOADS_LOADING",
    payload: {
      loading: true
    }
  });

  try {
    const [doctorId] = Object.keys(store.getState().profile.data);
    const currentClinicId = store.getState().clinics.currentClinic;

    // action.payload.patientsId
    if (currentClinicId && doctorId) {
      const previousDownloadsState = store.getState().downloads.data;

      if (
        !(
          isValidObject(previousDownloadsState) &&
          isValidObject(previousDownloadsState.templates) &&
          typeof previousDownloadsState.templates[currentClinicId] === "string"
        )
      ) {
        const templateDownloadableUrl = yield getFileUrl(
          `gs://${bucketNames.prescriptionTemplates}/${doctorId}/${currentClinicId}/default.pdf`
        );
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              templates: {
                [currentClinicId]: templateDownloadableUrl
              }
            }
          }
        });
      } else if (isValidObject(previousDownloadsState)) {
        const templateDownloadableUrl = yield getFileUrl(
          `gs://${bucketNames.prescriptionTemplates}/${doctorId}/${currentClinicId}/default.pdf`
        );
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...previousDownloadsState,
              templates: {
                ...(isValidObject(previousDownloadsState.templates)
                  ? previousDownloadsState.templates
                  : {}),
                [currentClinicId]: templateDownloadableUrl
              }
            }
          }
        });
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

function* getPatientsImageDownloadUrlWorker(action) {
  yield put({
    type: "SET_DOWNLOADS_LOADING",
    payload: {
      loading: true
    }
  });
  try {
    // action.payload.patientsId
    if (isValidArray(action.payload.patientsData)) {
      const newPinnedPatientsObject = {};
      for (let i = 0; i < action.payload.patientsData.length; i++) {
        try {
          const url = `gs://${bucketNames.nintoProfilePictures}/patients/${action.payload.patientsData[i].phoneNumber}/${action.payload.patientsData[i].pid}.png`;
          const downloadableUrl = yield getFileUrl(url);
          newPinnedPatientsObject[action.payload.patientsData[i].pid] =
            downloadableUrl;
        } catch (error) {
          newPinnedPatientsObject[action.payload.patientsData[i].pid] = null;
        }
      }
      const previousDownloadsState = store.getState().downloads.data;

      if (isValidObject(previousDownloadsState)) {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              ...(isValidObject(previousDownloadsState)
                ? previousDownloadsState
                : {}),
              profilePictures: {
                ...(isValidObject(previousDownloadsState.profilePictures)
                  ? previousDownloadsState.profilePictures
                  : {}),
                patients: {
                  ...(isValidObject(previousDownloadsState.profilePictures) &&
                  isValidObject(previousDownloadsState.profilePictures.patients)
                    ? previousDownloadsState.profilePictures.patients
                    : {}),
                  ...newPinnedPatientsObject
                }
              }
            }
          }
        });
      } else {
        yield put({
          type: "SET_DOWNLOADS",
          payload: {
            data: {
              profilePictures: {
                patients: {
                  ...newPinnedPatientsObject
                }
              }
            }
          }
        });
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    setErrorStatus(error);
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  }
}

export function* downloadsWatcher() {
  yield all([
    takeEvery("GET_DOCUMENT_DOWNLOAD_URL", getDocumentDownloadUrlWorker),
    takeEvery("GET_USER_IMAGE_DOWNLOAD_URL", getUserImageDownloadUrl),
    takeEvery(
      "GET_PINNED_PATIENTS_IMAGE_DOWNLOAD_URL",
      getPinnedPatientsImageDownloadUrlWorker
    ),
    takeEvery(
      "GET_CONNECTED_CLINICS_IMAGE_DOWNLOAD_URL",
      getConnectedClinicsImageDownloadUrlWorker
    ),
    takeEvery(
      "GET_UPLOADER_IMAGE_DOWNLOAD_URL",
      getUploaderImageDownloadUrlWorker
    ),
    takeEvery(
      "GET_PRESCRIPTION_TEMPLATE_DOWNLOAD_URL",
      getPrescriptionTemplateDownloadUrlWorker
    ),
    takeEvery(
      "GET_PATIENTS_IMAGE_DOWNLOAD_URL",
      getPatientsImageDownloadUrlWorker
    )
  ]);
}

const newValueWithPreviousState = (type, object) => {
  const previousDownloadsState = store.getState().downloads.data;
  if (isValidObject(previousDownloadsState)) {
    return {
      ...(isValidObject(previousDownloadsState) ? previousDownloadsState : {}),
      profilePictures: {
        ...(isValidObject(previousDownloadsState.profilePictures)
          ? previousDownloadsState.profilePictures
          : {}),
        [type]: {
          ...(isValidObject(previousDownloadsState.profilePictures) &&
          isValidObject(previousDownloadsState.profilePictures[type])
            ? previousDownloadsState.profilePictures[type]
            : {}),
          ...object
        }
      }
    };
  } else {
    return {
      profilePictures: {
        [type]: {
          ...object
        }
      }
    };
  }
};
