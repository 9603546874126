import store from "../store/store";
import { abdmPatientsActionTypes } from "./saga";
/**
 * @module AbdmPatientSaga
 */

/**
 * search patient
 * @param {string} searchKey
 * @param {string} type
 */
export function findPatient(searchKey, type) {
  store.dispatch({
    type: abdmPatientsActionTypes.FIND_PATIENT,
    payload: {
      searchKey: searchKey,
      type: type
    }
  });
}

/**
 * send subscription request
 * @param {string} expiryMonth
 * @param {string} healthId
 * @param {string} patientId
 */
export function sendSubscriptionRequest(expiryMonth, healthId, patientId) {
  store.dispatch({
    type: abdmPatientsActionTypes.SEND_SUBSCRIPTION_REQUEST,
    payload: {
      expiryMonth: expiryMonth,
      healthId: healthId,
      patientId: patientId
    }
  });
}

/**
 * check if patient connected to abdm
 */
export function checkIfPatientConnected() {
  store.dispatch({
    type: abdmPatientsActionTypes.CHECK_IF_PATIENT_CONNECTED_TO_ABDM
  });
}
/**
 * check if patient connected to ninto
 */
export function checkIfPatientConnectedToNinto() {
  store.dispatch({
    type: abdmPatientsActionTypes.CHECK_IF_PATIENT_CONNECTED_TO_NINTO
  });
}
/**
 * send consent request to patient
 * @param {object} data
 */
export function sendConsentRequest(data) {
  store.dispatch({
    type: abdmPatientsActionTypes.SEND_CONSENT_REQUEST,
    payload: data
  });
}
/**
 * accept ninto invite
 * @param {string} otp
 * @param {string} requestId
 */
export function nintoAcceptInvite(otp, requestId) {
  store.dispatch({
    type: abdmPatientsActionTypes.NINTO_ACCEPT_INVITE,
    payload: {
      otp: otp,
      requestId: requestId
    }
  });
}

/**
 * check if subscription request present
 * @param {string} healthId
 * @param {string} patientId
 */
export function isSubscriptionRequestPresent(healthId, patientId) {
  store.dispatch({
    type: abdmPatientsActionTypes.IS_SUBSCRIPTION_REQUEST_PRESENT,
    payload: {
      healthId: healthId,
      patientId: patientId
    }
  });
}
/**
 * clear patient search data
 */
export function clearPatientSearchData() {
  store.dispatch({
    type: abdmPatientsActionTypes.REMOVE_ABDM_PATIENT_SEARCH_DATA
  });
}
/**
 * check if consent request present
 * @param {string} healthId
 * @param {string} patientId
 * @param {boolean} loading
 */
export function checkConsentRequestPresent(healthId, patientId, loading) {
  store.dispatch({
    type: abdmPatientsActionTypes.CHECK_CONSENT_REQUEST_PRESENT,
    payload: {
      healthId: healthId,
      patientId: patientId,
      loading: loading
    }
  });
}
/**
 * set pinned patient
 * @param {object} pinnedPatients
 */
export function putPinnedPatients(pinnedPatients) {
  store.dispatch({
    type: abdmPatientsActionTypes.PUT_PINNED_PATIENTS,
    payload: {
      pinnedPatients: pinnedPatients
    }
  });
}

/**
 * set followed patient
 * @param {object} followedPatients
 */
export function putFollowedPatients(followedPatients) {
  store.dispatch({
    type: abdmPatientsActionTypes.PUT_FOLLOWED_PATIENTS,
    payload: {
      followedPatients: followedPatients
    }
  });
}

/**
 * follow patient
 * @param {object} profileData
 */
export function followPatient(profileData) {
  store.dispatch({
    type: abdmPatientsActionTypes.FOLLOW_PATIENT,
    payload: {
      profileData: profileData
    }
  });
}
/**
 * delete follow patient
 * @param {string} patientId
 */
export function unFollowPatient(patientId) {
  store.dispatch({
    type: abdmPatientsActionTypes.UN_FOLLOW_PATIENT,
    payload: {
      patientId: patientId
    }
  });
}
/**
 * delete pin patient
 * @param {string} patientId
 */
export function unPinPatient(patientId) {
  store.dispatch({
    type: abdmPatientsActionTypes.UN_PIN_PATIENT,
    payload: {
      patientId: patientId
    }
  });
}
